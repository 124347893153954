import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Brands from "../../components/Brands";
import CollapseContent from "../../components/CollapseContent";
import FormComponent from "../../components/FormComponent";
import { BreadCrumb } from "../../components/GlobalStyle";
import InfoTwo from "../../components/InfoTwo";
import WhyGood from "../../components/WhyGood";
import DotsMenu from "../../components/DotsMenu";

const Career = () => {
  const { t, i18n } = useTranslation();
  const { info } = useSelector((state) => state);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <div className="container">
        <BreadCrumb>
          <Link to="/">{t("footer_nav.main")}</Link>
          <span className="line"> / </span>
          <span>{t("footer_nav.career")}</span>
        </BreadCrumb>
      </div>
      <DotsMenu />
      <InfoTwo
        title={t("footer_nav.career")}
        tt={true}
        text={<>{t("another.career_desc")}</>}
      />
      <FormComponent info={info} title={t("another.fill_form")} />
      <WhyGood
        title={t("why_good.titlemain")}
        title1={t("why_good.title1")}
        title2={t("why_good.title2")}
        title3={t("why_good.title3")}
        title4={t("why_good.title4")}
        desc1={t("why_good.desc1")}
        desc2={t("why_good.desc2")}
        desc3={t("why_good.desc3")}
        desc4={t("why_good.desc4")}
        st={true}
      />
      <Brands title={t("another.brandwork")} />
    </>
  );
};

export default Career;
