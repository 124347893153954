import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { styled } from "styled-components";
import Brand from "../img/svg/brand.svg";
import Facebook from "../img/svg/facebook.svg";
import Instagram from "../img/svg/instagram.svg";
import Telegram from "../img/svg/telegram.svg";
import Phone from "../img/svg/phone.svg";
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import { getLanguage } from "../helpers/language";
import { useTranslation } from "react-i18next";
import { HashLink, NavHashLink } from "react-router-hash-link";

const Style = styled.section`
  background: #fff;
  border-bottom: 0.5px solid #f2f7fb;
  position: fixed;
  width: 100%;
  z-index: 999;
  .header {
    padding: 16px 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    .links {
      display: flex;
      li {
        margin-right: 24px;

        &:nth-last-child(1) {
          margin-right: 0;
        }
        a {
          color: #02324a;
          font-family: "Geologica-Medium", sans-serif;
          font-size: 14px;
          line-height: normal;
          transition: 300ms;
          &:hover {
            color: #0193de;
          }
        }
        .careerlink {
          border: 1.5px solid #0193de;
          padding: 4px 8px;
          border-radius: 8px;
        }
      }

      @media only screen and (max-width: 992px) and (min-width: 320px) {
        display: none;
      }
    }
    .active_link {
      color: #0193de !important;
      border: 1.5px solid #0193de;
      padding: 4px 8px;
      border-radius: 8px;
    }
    .social_flex {
      display: flex;
      .drop_menu {
        margin-right: 24px;
        @media only screen and (max-width: 576px) and (min-width: 320px) {
          margin-right: 6px;
        }
      }
      .menuLine {
        display: none;
        color: #02324a;
        font-family: "Geologica-Medium", sans-serif;
        font-size: 24px;
        line-height: normal;
        transition: 300ms;
        @media only screen and (max-width: 576px) and (min-width: 320px) {
          display: block;
        }
      }
      .social {
        display: flex;
        a {
          color: #0193de;
          text-align: right;
          font-family: "Geologica-Medium", sans-serif;
          font-size: 16px;
          line-height: normal;
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          span {
            margin-right: 8px;
          }
        }

        @media only screen and (max-width: 576px) and (min-width: 320px) {
          display: none;
        }
      }
    }
  }
`;

const MobileStyle = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #fff;
  padding-top: 100px;
  z-index: 990;
  .links_two {
    li {
      display: flex;
      justify-content: center;
      margin: 10px 0;
      a {
        color: #02324a;
        font-family: "Geologica-SemiBold", sans-serif;
        font-size: 26px;
        line-height: normal;
      }
    }
  }
`;

const Header = ({ phone }) => {
  const [mobile, setMobile] = useState(false);

  const handleMobile = () => setMobile(!mobile);

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const { t, i18n } = useTranslation();
  const lan = getLanguage();

  const onLanguageHandle = (newLang) => {
    i18n.changeLanguage(newLang);
    window.localStorage.setItem("language", newLang);
  };

  return (
    <>
      <Style>
        <div className="container">
          <div className="header">
            <NavLink
              to="/"
              onClick={() => {
                setMobile(false);
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
            >
              <img src={Brand} alt="" />
            </NavLink>
            <ul className="links">
              <li>
                <HashLink to="/#comp">{t("footer_nav.company")}</HashLink>
              </li>
              <li>
                <HashLink to="/#servicess">{t("footer_nav.service")}</HashLink>
              </li>
              <li>
                <HashLink to="/#clients">{t("footer_nav.clients")}</HashLink>
              </li>
              <li>
                <HashLink to="/#formm">{t("footer_nav.contact")}</HashLink>
              </li>
              <li>
                {/* <HashLink to="/#question">{t("footer_nav.faq")}</HashLink> */}

                <HashLink to="/#question">{t("footer_nav.faq")}</HashLink>
              </li>
              <li>
                <NavLink
                  className={
                    splitLocation[1] === "career" ? "active_link" : "careerlink"
                  }
                  to="/career"
                >
                  {t("footer_nav.career")}
                </NavLink>
              </li>
            </ul>
            <div className="social_flex">
              {/* <div className="drop_menu">
                <DropdownMenu
                  trigger={
                    lan === "ru" ? (
                      <>{t("navbar.ru")}</>
                    ) : lan === "uz" ? (
                      <>{t("navbar.uz")}</>
                    ) : null
                  }
                >
                  <DropdownItemGroup>
                    <DropdownItem onClick={() => onLanguageHandle("ru")}>
                      {t("navbar.ru")}
                    </DropdownItem>
                    <DropdownItem onClick={() => onLanguageHandle("uz")}>
                      {t("navbar.uz")}
                    </DropdownItem>
                  </DropdownItemGroup>
                </DropdownMenu>
              </div> */}
              {/* let a = "test Uchun Nimadur uchun UCHUN";

              let aa = a.replace(/Uchun|uchun|UCHUN/g, "tt");

              console.log(aa); */}
              <div className="social">
                <a href={`tel:+${phone?.replace(/['('')'' ''+'-]/g, "")}`}>
                  <span>{phone}</span>
                  <img src={Phone} alt="" />
                </a>
              </div>
              <div className="menuLine cursor" onClick={() => handleMobile()}>
                {mobile === true ? <>&#x2715;</> : <>&#9776;</>}
              </div>
            </div>
          </div>
        </div>
      </Style>
      {mobile === true ? (
        <MobileStyle>
          {" "}
          <ul className="links_two">
            <li>
              <HashLink to="/#comp" onClick={() => setMobile(false)}>
                {t("footer_nav.company")}
              </HashLink>
            </li>
            <li>
              <HashLink to="/#servicess" onClick={() => setMobile(false)}>
                {t("footer_nav.service")}
              </HashLink>
            </li>
            <li>
              <HashLink to="/#clients" onClick={() => setMobile(false)}>
                {t("footer_nav.clients")}
              </HashLink>
            </li>
            <li>
              <HashLink to="/#formm" onClick={() => setMobile(false)}>
                {t("footer_nav.contact")}
              </HashLink>
            </li>
            <li>
              <HashLink to="/#question" onClick={() => setMobile(false)}>
                {t("footer_nav.faq")}
              </HashLink>
            </li>
            <li>
              <NavLink
                className={splitLocation[1] === "career" ? "active_link" : ""}
                onClick={() => setMobile(false)}
                to="/career"
              >
                {t("footer_nav.career")}
              </NavLink>
            </li>
          </ul>
        </MobileStyle>
      ) : null}
    </>
  );
};

export default Header;
