import React from "react";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { styled } from "styled-components";
import Brand from "../img/svg/brand_white.svg";
import Facebook from "../img/svg/facebook.svg";
import Instagram from "../img/svg/instagram.svg";
import Telegram from "../img/svg/telegram.svg";
import NazirovBrandWhite from "../img/nazirov light.png";

const Style = styled.section`
  background: #02324a;

  margin-top: 96px;
  @media only screen and (max-width: 576px) and (min-width: 320px) {
    margin-top: 50px;
  }
  .header {
    padding: 32px 0 24px 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    .links {
      display: flex;
      li {
        margin-right: 24px;
        &:nth-last-child(1) {
          margin-right: 0;
        }
        a {
          color: #fff;
          font-family: "Geologica-Medium", sans-serif;
          font-size: 14px;
          line-height: normal;
          transition: 300ms;
          &:hover {
            color: #cad3df;
          }
        }
      }
      @media only screen and (max-width: 992px) and (min-width: 320px) {
        display: none;
      }
    }
    .active_link {
      color: #cad3df !important;
    }
    .social {
      display: flex;
      a {
        margin-right: 24px;
        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
  }
  .sub {
    border-top: 1px solid #38525f;
    padding: 24px 0 32px 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
    .sub_t {
      color: #cad3df;
      font-family: "Geologica-Light", sans-serif;
      font-size: 14px;
      line-height: normal;
    }
    img {
      width: 100px;
    }
    span {
      margin-right: 10px;
    }
    @media only screen and (max-width: 576px) and (min-width: 320px) {
      flex-direction: column;
      text-align: center;
      .sub_t {
        margin-top: 5px;
      }
    }
  }
`;

const Footer = () => {
  const { t, i18n } = useTranslation();
  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <>
      <Style>
        <div className="container">
          <div className="header">
            <Link
              to="/"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
              }}
            >
              <img src={Brand} alt="" />
            </Link>
            <ul className="links">
              <li>
                <NavLink
                  className={splitLocation[1] === "about" ? "active_link" : ""}
                  to="/about"
                >
                  {t("footer_nav.company")}
                </NavLink>
              </li>
              <li>
                <HashLink smooth to="/#servicess">
                  {t("footer_nav.service")}
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#clients">
                  {t("footer_nav.clients")}
                </HashLink>
              </li>
              <li>
                <NavLink
                  className={splitLocation[1] === "career" ? "active_link" : ""}
                  to="/career"
                >
                  {t("footer_nav.career")}
                </NavLink>
              </li>
              <li>
                <HashLink smooth to="/#question">
                  {t("footer_nav.faq")}
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#formm">
                  {t("footer_nav.contact")}
                </HashLink>
              </li>
            </ul>
            <div className="social">
              <a href="" target="_blank">
                <img src={Facebook} alt="" />
              </a>
              <a
                href="https://instagram.com/nasvyazi.uz?igshid=OGQ5ZDc2ODk2ZA%3D%3D&utm_source=qr"
                target="_blank"
              >
                <img src={Instagram} alt="" />
              </a>
              <a href="https://t.me/nasvyazi_uz" target="_blank">
                <img src={Telegram} alt="" />
              </a>
            </div>
          </div>
          <div className="sub">
            <p className="sub_t">© Nasvyazi 2023</p>
            <p className="sub_t">{t("footer_nav.sub_footer")}</p>
            <p
              className="sub_t"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span>developed by </span>
              <a href="https://nazirov.co/" target="_blank">
                <img src={NazirovBrandWhite} alt="" />
              </a>
            </p>
          </div>
        </div>
      </Style>
    </>
  );
};

export default Footer;
