import React, { useState, useEffect, useRef } from "react";

// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { styled } from "styled-components";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AboutSlider from "../img/aboutslider.png";
import Arrowleft from "../img/svg/arrowleft.svg";
import Arrowright from "../img/svg/arrowright.svg";
import { useTranslation } from "react-i18next";

const CustomPage = styled.div``;
const Style = styled.section`
  /* margin-bottom: 86px; */
  overflow: hidden;
  @media only screen and (max-width: 576px) and (min-width: 320px) {
    /* margin-bottom: 30px; */
  }
  .slick-list {
    overflow: visible;
  }
  .swiper-pagination-bullet {
    transition: 300ms;
  }
  .swiper {
    padding-bottom: 38px;
    overflow: visible !important;
  }
  .swiper-pagination-bullet-active {
    width: 60px;
    border-radius: 16px;
  }
  .card {
    position: relative;
    border-radius: 24px;
    width: calc(100% - 30px) !important;
    margin-left: 15px;
    &:focus-visible {
      outline: none;
    }
    .card_imgMain {
      position: relative;
      overflow: hidden;
      border: 1px solid #cad3df;
      border-radius: 14px;
      .card_img {
        width: 100%;
        object-fit: cover;
        height: 530px;
        @media only screen and (max-width: 700px) and (min-width: 320px) {
          object-fit: contain;
          height: 430px !important;
        }
        z-index: 1;
        @media only screen and (max-width: 576px) and (min-width: 320px) {
          height: 350px;
        }
      }
      .card_imgLayer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.4000000059604645;
      }
    }
    z-index: 1;
    .t1 {
      margin-top: 32px;
      color: #02324a;
      font-family: "Geologica-SemiBold", sans-serif;
      font-size: 22px;
      line-height: normal;
    }
    .t2 {
      margin-top: 8px;
      color: #7c8b9f;
      font-family: "Geologica-Light", sans-serif;
      font-size: 16px;
      line-height: normal;
    }
  }
  .slick-prev {
    left: -10px;
    transform: translate(0, -90px);
    z-index: 2;
  }
  .slick-next {
    right: 20px;
    transform: translate(0, -90px);
    z-index: 2;
  }
  /* .slick-slide {
    .card {
      transition: 200ms;
      .layer {
        position: absolute;
        width: 100%;
        height: 530px;
        z-index: 4;
        background: rgb(248, 248, 247);
        top: 0;
        left: 0;
        opacity: 0.4000000059604645;
        transition: 200ms;
      }
    }
  }
  .slick-active {
    .layer {
      opacity: 0 !important;
    }
  } */
  /* .slick-dots li.slick-active button {
    width: 60px;
    border-radius: 16px;
  } */
  .slick-dots {
    bottom: 0;
  }
  .slick-track {
    padding-bottom: 52px;
  }
  /* .slick-dots {
    ul {
      li {
        margin: 0;
      }
    }
    .custompage {
      background: #cad3df;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
    .slick-active {
      width: 60px;
      margin-right: 5px;
      .custompage {
        transition: 200ms ease-in;
        position: relative;
        background: #0193de;
        width: 60px;
        height: 12px;
        border-radius: 8px;
        z-index: 99;
      }
    }
  } */

  .slick-dots {
    /* transform: translate(0, 20px); */
    ul {
      li {
        margin: 0;
      }
    }
    .custompage {
      background: #cad3df;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
    .slick-active {
      width: 60px;
      margin-right: 5px;
      overflow: hidden;
      .custompage {
        transition: 250ms ease-in-out;
        overflow: hidden;
        position: relative;
        background: #cad3df;
        width: 60px;
        height: 12px;
        border-radius: 8px;
      }
    }
  }
  .slick-prev:before,
  .slick-next:before {
    display: none;
  }
`;

const ArrowPrev = ({ className, style, onClick }) => (
  <button onClick={onClick} className={className}>
    <div>
      <img src={Arrowleft} alt="" />
    </div>
  </button>
);
const ArrowNext = ({ className, style, onClick }) => (
  <button onClick={onClick} className={className}>
    <div>
      <img src={Arrowright} alt="" />
    </div>
  </button>
);

const SwiperGroup2 = ({
  cartificateList,
  title,
  actionThreeSlider,
  setCartificateList,
}) => {
  const [autoPly, setAutoPly] = useState(false);
  const [cIndex, setCIndex] = useState();
  const { t, i18n } = useTranslation();
  let sliderRef = useRef();

  useEffect(() => {
    if (actionThreeSlider) {
      sliderRef.slickPlay();

      console.log(sliderRef);
    }
  }, [sliderRef, actionThreeSlider]);

  let settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow:
      cartificateList?.length === 1 ? 1 : cartificateList?.length === 2 ? 2 : 3,
    slidesToScroll: 1,
    spaceBetween: 30,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <ArrowPrev />,
    nextArrow: <ArrowNext />,
    autoplay: autoPly,
    autoplaySpeed: 2500,
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <div className="custompage"></div>,
    beforeChange: (oldIndex, newIndex) => {
      console.log(oldIndex);
      if (oldIndex) {
        setCIndex(oldIndex);
      }
    },
  };

  const onMouseDownF = () => {
    sliderRef.slickPause();
    setAutoPly(false);
  };

  const onMouseUpF = () => {
    sliderRef.slickPlay();
    setAutoPly(true);
  };

  return (
    <>
      <Style>
        <div
          className={
            cartificateList?.length === 1
              ? `container_three`
              : cartificateList?.length === 2
              ? `container_two`
              : `container`
          }
        >
          <p className="title_app" style={{ marginBottom: "36px" }}>
            {title}
          </p>

          {cartificateList && cartificateList?.length > 0 ? (
            <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
              {cartificateList?.map((item, index) => (
                <div
                  className="card"
                  key={index}
                  onMouseDown={onMouseDownF}
                  onMouseUp={onMouseUpF}
                >
                  <div className="card_imgMain">
                    <div
                      className="card_imgLayer"
                      style={
                        cIndex !== index - 1
                          ? { backgroundColor: item?.bColor }
                          : {}
                      }
                    />
                    <img src={`${item?.image}`} className="card_img" alt="" />
                  </div>
                  <div className="layer" />
                  <p className="t1">{item?.title}</p>
                  {/* <p className="t2">{item?.desc}</p> */}
                </div>
              ))}
            </Slider>
          ) : null}
        </div>
      </Style>
    </>
  );
};

export default SwiperGroup2;
