// import React, { useState } from "react";
// import { styled } from "styled-components";
// import Subtract from "../img/frame17.png";
// import { useTranslation } from "react-i18next";
// import DotsMenu from "./DotsMenu";

// const Style = styled.div`
//   position: relative;
//   height: 784px;
//   overflow: hidden;
//   user-select: none;
//   @media (max-width: 1300px) and (min-width: 1200px) {
//     height: 700px;
//   }
//   @media (max-width: 1200px) and (min-width: 1100px) {
//     height: 650px;
//   }
//   @media (max-width: 1100px) and (min-width: 1000px) {
//     height: 600px;
//   }
//   @media (max-width: 1000px) and (min-width: 900px) {
//   }

//   @media (max-width: 1000px) and (min-width: 700px) {
//     background: #f7fbfe;
//     height: auto;
//     padding: 80px 0;
//   }
//   @media only screen and (max-width: 700px) and (min-width: 320px) {
//     background: #f7fbfe;
//     height: auto;
//     padding: 40px 0;
//   }

//   @media (max-width: 765px) and (min-width: 576px) {
//   }
//   @media only screen and (max-width: 576px) and (min-width: 320px) {
//   }
//   .subtract_img {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     z-index: 1;

//     @media only screen and (max-width: 1000px) and (min-width: 320px) {
//       display: none;
//     }
//   }
//   .card {
//     display: flex;
//     width: 100%;
//     height: 784px;

//     @media (max-width: 1000px) and (min-width: 700px) {
//       height: auto;
//       padding: 80px 0;
//     }
//     @media only screen and (max-width: 700px) and (min-width: 320px) {
//       height: auto;
//       padding: 40px 0;
//     }

//     @media (max-width: 1300px) and (min-width: 1200px) {
//       height: 700px;
//     }
//     @media (max-width: 1200px) and (min-width: 1100px) {
//       height: 650px;
//     }
//     @media (max-width: 1100px) and (min-width: 1000px) {
//       height: 600px;
//     }
//     .left {
//       position: relative;
//       width: 50%;
//       z-index: 2;
//       display: flex;
//       align-items: center;

//       @media only screen and (max-width: 1000px) and (min-width: 320px) {
//         width: 90%;
//       }
//       .info {
//         .t1 {
//           color: #02324a;
//           font-family: "Geologica-Bold", sans-serif;
//           font-size: 46px;
//           line-height: normal;

//           @media only screen and (max-width: 700px) and (min-width: 320px) {
//             font-size: 27px;
//           }
//         }
//         .t2 {
//           margin-top: 16px;
//           color: #7c8b9f;
//           font-family: "Geologica-Light", sans-serif;
//           font-size: 16px;
//           line-height: 150%;
//         }
//         .btn1 {
//           margin-top: 36px;
//           padding: 16px 24px;
//           border-radius: 16px;
//           background: #0193de;
//           border: none;
//           color: #fff;
//           font-family: "Geologica-SemiBold", sans-serif;
//           font-size: 16px;
//           line-height: normal;
//         }
//       }
//     }
//     .right {
//       position: relative;
//       width: 50%;
//       z-index: 2;
//       @media only screen and (max-width: 1000px) and (min-width: 320px) {
//         display: none;
//       }
//       .user_img {
//         position: absolute;
//         top: 29px;
//         right: 0;
//         z-index: 2;
//       }
//     }
//   }
//   .rightMenu {
//     .sub {
//       position: fixed;
//       top: 0;
//       right: -42px;
//       z-index: 4;
//       display: flex;
//       align-items: center;
//       height: 100%;
//       .block {
//         cursor: pointer;
//         display: flex;
//         align-items: center;
//         width: 86px;
//         height: 86px;
//         border-radius: 50%;
//         background: #0193de;
//         transition: 200ms;
//         img {
//           margin-left: 15px;
//         }
//       }
//       .block_active {
//         background: #02324a;
//       }
//       .menuss {
//         margin-right: 16px;
//         display: flex;
//         flex-direction: column;
//         transition: 200ms;
//         .menuss_sub {
//           color: #02324a;
//           font-family: "Geologica-SemiBold", sans-serif;
//           font-size: 16px;
//           line-height: normal;
//           border-radius: 16px;
//           border: 1px solid #bbc8d2;
//           background: #fff;
//           padding: 16px 24px;
//           margin-bottom: 12px;
//           text-align: center;
//         }
//       }
//     }
//   }
// `;
// const HeaderMain = ({ question }) => {
//   const { t, i18n } = useTranslation();

//   return (
//     <>
//       <Style>
//         <img src={Subtract} className="subtract_img" alt="" />
//         <div className="container">
//           <div className="card">
//             <div className="left">
//               <div className="info">
//                 <p className="t1">{t("header_main.title")}</p>
//                 <p className="t2">{t("header_main.desc")}</p>
//                 <div style={{ marginTop: "40px" }}>
//                   <a
//                     href="https://t.me/nasvyazi_uz"
//                     target="_blank"
//                     className="btn1"
//                   >
//                     {t("header_main.btn_header")}
//                   </a>
//                 </div>
//               </div>
//             </div>
//             {/* <div className="right">
//               <img src={User} className="user_img" alt="" />
//             </div> */}
//           </div>
//         </div>
//         <DotsMenu />
//       </Style>
//     </>
//   );
// };

// export default HeaderMain;

import React, { useState } from "react";
import { styled } from "styled-components";
import Subtract from "../img/Subtract.png";
// import Subtract1 from "../img/ss.png";
import Subtract1 from "../img/headerimg.webp";
import { useTranslation } from "react-i18next";
import DotsMenu from "./DotsMenu";

const Style = styled.div`
  position: relative;
  height: 784px;
  overflow: hidden;
  user-select: none;
  @media (max-width: 1300px) and (min-width: 1200px) {
    height: 700px;
  }
  @media (max-width: 1200px) and (min-width: 1100px) {
    height: 650px;
  }
  @media (max-width: 1100px) and (min-width: 1000px) {
    height: 600px;
  }
  @media (max-width: 1000px) and (min-width: 900px) {
  }

  @media (max-width: 1000px) and (min-width: 700px) {
    background: #f7fbfe;
    height: auto;
    padding: 80px 0;
  }
  @media only screen and (max-width: 700px) and (min-width: 320px) {
    background: #f7fbfe;
    height: auto;
    padding: 40px 0;
  }

  @media (max-width: 765px) and (min-width: 576px) {
  }
  @media only screen and (max-width: 576px) and (min-width: 320px) {
  }
  .subtract_img1 {
    position: absolute;
    bottom: 50px;
    right: 0px;

    width: 550px;
    height: auto;
    z-index: 2;

    @media only screen and (max-width: 1000px) and (min-width: 320px) {
      display: none;
    }
  }
  .subtract_img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    z-index: 1;

    @media only screen and (max-width: 1000px) and (min-width: 320px) {
      display: none;
    }
  }
  .layer {
    height: 160px;
    background-color: #fff;
    position: absolute;
    bottom: -80px;
    right: -46px;
    z-index: 3;
    border-radius: 200vh 200vh 0 0/20vh 20vh 0 0;
    width: calc(100% + 144px);

    @media only screen and (max-width: 700px) and (min-width: 320px) {
      display: none;
    }
  }
  .card {
    display: flex;
    width: 100%;
    height: 784px;

    @media (max-width: 1000px) and (min-width: 700px) {
      height: auto;
      padding: 80px 0;
    }
    @media only screen and (max-width: 700px) and (min-width: 320px) {
      height: auto;
      padding: 40px 0;
    }

    @media (max-width: 1300px) and (min-width: 1200px) {
      height: 700px;
    }
    @media (max-width: 1200px) and (min-width: 1100px) {
      height: 650px;
    }
    @media (max-width: 1100px) and (min-width: 1000px) {
      height: 600px;
    }
    .left {
      position: relative;
      width: 50%;
      z-index: 2;
      display: flex;
      align-items: center;

      @media only screen and (max-width: 1000px) and (min-width: 320px) {
        width: 90%;
      }
      .info {
        .t1 {
          color: #02324a;
          font-family: "Geologica-Bold", sans-serif;
          font-size: 46px;
          line-height: normal;

          @media only screen and (max-width: 700px) and (min-width: 320px) {
            font-size: 27px;
          }
        }
        .t2 {
          margin-top: 16px;
          color: #7c8b9f;
          font-family: "Geologica-Light", sans-serif;
          font-size: 16px;
          line-height: 150%;
        }
        .btn1 {
          margin-top: 36px;
          padding: 16px 24px;
          border-radius: 16px;
          background: #0193de;
          border: none;
          color: #fff;
          font-family: "Geologica-SemiBold", sans-serif;
          font-size: 16px;
          line-height: normal;
        }
      }
    }
    .right {
      position: relative;
      width: 50%;
      z-index: 2;
      @media only screen and (max-width: 1000px) and (min-width: 320px) {
        display: none;
      }
      .user_img {
        position: absolute;
        top: 29px;
        right: 0;
        z-index: 2;
      }
    }
  }
  .rightMenu {
    .sub {
      position: fixed;
      top: 0;
      right: -42px;
      z-index: 4;
      display: flex;
      align-items: center;
      height: 100%;
      .block {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 86px;
        height: 86px;
        border-radius: 50%;
        background: #0193de;
        transition: 200ms;
        img {
          margin-left: 15px;
        }
      }
      .block_active {
        background: #02324a;
      }
      .menuss {
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        transition: 200ms;
        .menuss_sub {
          color: #02324a;
          font-family: "Geologica-SemiBold", sans-serif;
          font-size: 16px;
          line-height: normal;
          border-radius: 16px;
          border: 1px solid #bbc8d2;
          background: #fff;
          padding: 16px 24px;
          margin-bottom: 12px;
          text-align: center;
        }
      }
    }
  }
`;
const HeaderMain = ({ question }) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Style>
        <img src={Subtract} className="subtract_img" alt="" />
        <div className="layer" />
        <div className="container">
          <div className="card">
            <div className="left">
              <div className="info">
                <p className="t1">{t("header_main.title")}</p>
                <p className="t2">{t("header_main.desc")}</p>
                <div style={{ marginTop: "40px" }}>
                  <a
                    href="https://t.me/nasvyazi_uz"
                    target="_blank"
                    className="btn1"
                  >
                    {t("header_main.btn_header")}
                  </a>
                </div>
              </div>
            </div>
            <div className="right" style={{ position: "relative" }}>
              <img src={Subtract1} className="subtract_img1" alt="" />
            </div>
          </div>
        </div>
        <DotsMenu />
      </Style>
    </>
  );
};

export default HeaderMain;
