import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "./Footer";
import Header from "./Header";
import Loading from "../components/Loading";
import { setLoading, setImages, setTeams, setPartners } from "../redux";
import { GetNotAuthInstance } from "../helpers/httpClient";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const UserSide = (props) => {
  const { loading } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  useEffect(() => {
    getImages();
    getTeams();
  }, [i18n.language]);

  const getTeams = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get("api/v1/our-teams")
      .then((res) => {
        if (res.status === 200) {
          let data = res?.data?.data;
          if (data) {
            dispatch(setTeams(data));
          } else {
            dispatch(setTeams([]));
          }
        }
      })
      .catch((error) => dispatch(setTeams([])))
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const getImages = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get("api/v1/images")
      .then((res) => {
        if (res.status === 200) {
          let data = res?.data?.data;
          if (data) {
            dispatch(setImages(data));
          } else {
            dispatch(setImages([]));
          }
        }
      })
      .catch((error) => dispatch(setImages([])))
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  // const getInfo = () => {
  //   dispatch(setLoading(true));
  //   GetNotAuthInstance()
  //     .get("api/v1/statics")
  //     .then((res) => {
  //       if (res.status === 200) {
  //         let data = res?.data?.data;
  //         if (data) {
  //           dispatch(setInfo(data));
  //         } else {
  //           dispatch(setInfo([]));
  //         }
  //         const urlHash = window.location.hash;
  //         if (urlHash.substring(1) === "formm") {
  //           setTimeout(() => {
  //             if (urlHash.length) {
  //               const element = document.getElementById(urlHash.substring(1));
  //               element.scrollIntoView({
  //                 behavior: "smooth",
  //                 block: "start",
  //               });
  //             }
  //           }, 80);
  //         }
  //       }
  //     })
  //     .catch((error) => dispatch(setInfo([])))
  //     .finally(() => {
  //       dispatch(setLoading(false));
  //     });
  // };

  return (
    <>
      {loading ? <Loading /> : ""}
      <Header
        phone={"(78) 129-88-88"}
        // phone={info?.phone}
      />
      <div style={{ paddingTop: "75px" }}>{props.children}</div>
      <Footer />
      {/* {splitLocation[1] === "faq" ? null : <Footer />} */}
      {/* {splitLocation[1] === "faq" ? null : } */}
    </>
  );
};

export default UserSide;
