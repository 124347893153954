import React from "react";
import Threedots from "../img/svg/threedots.svg";
import Closemenu from "../img/svg/closemenu.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { styled } from "styled-components";
import { HashLink } from "react-router-hash-link";

const Style = styled.div`
  position: relative;
  overflow: hidden;
  user-select: none;
  /* @media (max-width: 1300px) and (min-width: 1200px) {
    height: 700px;
  }
  @media (max-width: 1200px) and (min-width: 1100px) {
    height: 650px;
  }
  @media (max-width: 1100px) and (min-width: 1000px) {
    height: 600px;
  } */
  @media (max-width: 1000px) and (min-width: 900px) {
  }

  @media (max-width: 1000px) and (min-width: 700px) {
    background: #f7fbfe;
    height: auto;
    padding: 80px 0;
  }
  /* @media only screen and (max-width: 700px) and (min-width: 320px) {
    background: #f7fbfe;
    height: auto;
    padding: 40px 0;
  } */

  @media (max-width: 765px) and (min-width: 576px) {
  }
  @media only screen and (max-width: 576px) and (min-width: 320px) {
  }
  .subtract_img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    @media only screen and (max-width: 1000px) and (min-width: 320px) {
      display: none;
    }
  }
  .card {
    display: flex;
    width: 100%;
    height: 784px;

    @media (max-width: 1000px) and (min-width: 700px) {
      height: auto;
      padding: 80px 0;
    }
    @media only screen and (max-width: 700px) and (min-width: 320px) {
      height: auto;
      padding: 40px 0;
    }

    @media (max-width: 1300px) and (min-width: 1200px) {
      height: 700px;
    }
    @media (max-width: 1200px) and (min-width: 1100px) {
      height: 650px;
    }
    @media (max-width: 1100px) and (min-width: 1000px) {
      height: 600px;
    }
    .left {
      position: relative;
      width: 50%;
      z-index: 2;
      display: flex;
      align-items: center;

      @media only screen and (max-width: 1000px) and (min-width: 320px) {
        width: 90%;
      }
      .info {
        .t1 {
          color: #02324a;
          font-family: "Geologica-Bold", sans-serif;
          font-size: 46px;
          line-height: normal;

          @media only screen and (max-width: 700px) and (min-width: 320px) {
            font-size: 27px;
          }
        }
        .t2 {
          margin-top: 16px;
          color: #7c8b9f;
          font-family: "Geologica-Light", sans-serif;
          font-size: 16px;
          line-height: 150%;
        }
        .btn1 {
          margin-top: 36px;
          padding: 16px 24px;
          border-radius: 16px;
          background: #0193de;
          border: none;
          color: #fff;
          font-family: "Geologica-SemiBold", sans-serif;
          font-size: 16px;
          line-height: normal;
        }
      }
    }
    .right {
      position: relative;
      width: 50%;
      z-index: 2;
      @media only screen and (max-width: 1000px) and (min-width: 320px) {
        display: none;
      }
      .user_img {
        position: absolute;
        top: 29px;
        right: 0;
        z-index: 2;
      }
    }
  }
  .rightMenu {
    .sub {
      position: fixed;
      top: 0;
      right: -42px;
      z-index: 4;
      display: flex;
      align-items: center;
      height: 100%;
      .block {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: 86px;
        height: 86px;
        border-radius: 50%;
        background: #0193de;
        transition: 200ms;
        img {
          margin-left: 15px;
        }
      }
      .block_active {
        background: #02324a;
      }
      .menuss {
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        transition: 200ms;
        .menuss_sub {
          color: #02324a;
          font-family: "Geologica-SemiBold", sans-serif;
          font-size: 16px;
          line-height: normal;
          border-radius: 16px;
          border: 1px solid #bbc8d2;
          background: #fff;
          padding: 16px 24px;
          margin-bottom: 12px;
          text-align: center;
        }
      }
    }
  }
`;
const DotsMenu = () => {
  const [menu, setMenu] = useState(false);
  const { t, i18n } = useTranslation();

  const handleMenu = () => {
    setMenu(!menu);
  };
  return (
    <Style>
      <div className="rightMenu">
        <div className="sub">
          {menu === true ? (
            <div className="menuss">
              <HashLink
                smooth
                to="/#formm"
                className="menuss_sub"
                onClick={() => setMenu(false)}
              >
                {t("header_main.btn_header")}
              </HashLink>
              <a
                href="https://t.me/nasvyazi_uz"
                target="_blank"
                onClick={() => setMenu(false)}
                className="menuss_sub"
              >
                {t("header_main.call")}
              </a>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSc_Gm3U1Lb3XQcZWRbAgrlS4JhvTYmhO3wk5p3wLgGhjnHG1Q/viewform?usp=sf_link"
                target="_blank"
                onClick={() => setMenu(false)}
                className="menuss_sub"
              >
                {t("header_main.tz")}
              </a>
            </div>
          ) : null}

          <div
            className={menu === false ? "block" : "block block_active"}
            onClick={() => handleMenu()}
          >
            {menu === false ? (
              <img src={Threedots} className="threedots" alt="" />
            ) : (
              <img src={Closemenu} className="threedots" alt="" />
            )}
          </div>
        </div>
      </div>
    </Style>
  );
};

export default DotsMenu;
