import React, { useEffect, useRef } from "react";

// import { Swiper, SwiperSlide } from "swiper/react";
// import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { styled } from "styled-components";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AboutSlider from "../img/aboutslider.png";
import Arrowleft from "../img/svg/arrowleft.svg";
import Arrowright from "../img/svg/arrowright.svg";
import { useState } from "react";

const CustomPage = styled.div``;
const Style = styled.section`
  /* margin-bottom: 50px; */
  overflow: hidden;

  @media only screen and (max-width: 576px) and (min-width: 320px) {
    /* margin-bottom: 30px; */
  }
  .slick-list {
    overflow: visible;
  }
  .swiper-pagination-bullet {
    /* transition: 300ms; */
  }
  .swiper {
    padding-bottom: 38px;
    overflow: visible !important;
  }
  .swiper-pagination-bullet-active {
    width: 60px;
    border-radius: 16px;
  }
  .card {
    position: relative;
    border-radius: 24px;
    border: 1px solid #cad3df;
    width: calc(100% - 30px) !important;
    margin-left: 15px;
    &:focus-visible {
      outline: none;
    }
    .card_img {
      position: relative;
      width: 100%;
      height: 420px;
      object-fit: cover;
      border-radius: 24px;
      z-index: 1;

      @media only screen and (max-width: 576px) and (min-width: 320px) {
        height: 350px;
      }
    }
    z-index: 1;
  }
  .slick-prev {
    left: -95px;
    transform: translate(0, -40px);
    z-index: 2;
  }
  .slick-next {
    right: -65px;
    transform: translate(0, -40px);
    z-index: 2;
  }
  .slick-slide {
    .card {
      transition: 200ms;
      border-radius: 24px;
      .layer {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 4;
        background: rgb(248, 248, 247);
        top: 0;
        left: 0;
        border-radius: 24px;
        opacity: 0.4000000059604645;
        transition: 200ms;
      }
    }
  }
  .slick-active {
    .layer {
      opacity: 0 !important;
    }
  }
  /* .slick-dots li.slick-active button {
    width: 60px;
    border-radius: 16px;
  } */
  .slick-dots {
    bottom: 0;
  }
  .slick-track {
    padding-bottom: 52px;
  }
  /* .slick-track[data-index="0"] {
    .card {
      transition: 200ms;
      border-radius: 24px;
      .layer {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 44;
        background: rgb(248, 248, 247);
        background: red !important;
        top: 0;
        left: 0;
        border-radius: 24px;
        opacity: 0.4000000059604645;
        transition: 200ms;
      }
    }
  }  */
  .slick-dots {
    ul {
      li {
        /* transition: 300ms; */
        margin: 0;
      }
    }
    .custompage {
      background: #cad3df;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
    .slick-active {
      width: 60px;
      margin-right: 5px;
      overflow: hidden;
      .custompage {
        transition: 250ms ease-in-out;
        overflow: hidden;
        position: relative;
        background: #cad3df;
        width: 60px;
        height: 12px;
        border-radius: 8px;
      }
    }
  }
  .slick-prev:before,
  .slick-next:before {
    display: none;
  }
`;

const ArrowPrev = ({ className, style, onClick }) => (
  <button onClick={onClick} className={className}>
    <div>
      <img src={Arrowleft} alt="" />
    </div>
  </button>
);
const ArrowNext = ({ className, style, onClick }) => (
  <button onClick={onClick} className={className}>
    <div>
      <img src={Arrowright} alt="" />
    </div>
  </button>
);

const AboutSwiper = ({ images, actionOneSlider }) => {
  const [autoPly, setAutoPly] = useState(false);
  let sliderRef = useRef();

  useEffect(() => {
    if (actionOneSlider) {
      sliderRef.slickPlay();
    }
  }, [sliderRef, actionOneSlider]);

  let settings = {
    infinite: true,
    dots: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    spaceBetween: 30,
    autoplay: autoPly,
    autoplaySpeed: 2500,
    pauseOnFocus: true,
    prevArrow: <ArrowPrev />,
    nextArrow: <ArrowNext />,
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => <div className="custompage"></div>,
  };

  const onMouseDownF = () => {
    sliderRef.slickPause();
    setAutoPly(false);
  };

  const onMouseUpF = () => {
    sliderRef.slickPlay();
    setAutoPly(true);
  };

  return (
    <>
      <Style>
        <div className="container_two">
          {images && images?.length > 0 ? (
            <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
              {images?.map((item, index) => (
                <div
                  className="card"
                  key={index}
                  onMouseDown={onMouseDownF}
                  onMouseUp={onMouseUpF}
                >
                  <img src={item?.image} className="card_img" alt="" />
                  <div className="layer" />
                </div>
              ))}
            </Slider>
          ) : null}

          {/* <Swiper
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            pagination={{
              clickable: true,
            }}
            // autoplay={{
            //   delay: 5500,
            //   disableOnInteraction: false,
            // }}
            navigation={true}
            modules={[Pagination, Navigation, Autoplay]}
            initialSlide={1}
            className="mySwiper"
            autoHeight={true}
          >
            <SwiperSlide>
              <div className="card">
                <div className="info">
                  <div style={{ marginLeft: "16px" }}>
                    <p className="info_t1">Илья Андреев 1</p>
                    <p className="info_t2">Директор по развитию</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <div className="info">
                  <div style={{ marginLeft: "16px" }}>
                    <p className="info_t1">Илья Андреев 2</p>
                    <p className="info_t2">Директор по развитию</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <div className="info">
                  <div style={{ marginLeft: "16px" }}>
                    <p className="info_t1">Илья Андреев 3</p>
                    <p className="info_t2">Директор по развитию</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <div className="info">
                  <div style={{ marginLeft: "16px" }}>
                    <p className="info_t1">Илья Андреев 4</p>
                    <p className="info_t2">Директор по развитию</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <div className="info">
                  <div style={{ marginLeft: "16px" }}>
                    <p className="info_t1">Илья Андреев 5</p>
                    <p className="info_t2">Директор по развитию</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper> */}
        </div>
      </Style>
    </>
  );
};

export default AboutSwiper;
