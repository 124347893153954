import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useDispatch } from "react-redux";
import { setLoading } from "../redux";
import { GetNotAuthInstance } from "../helpers/httpClient";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, FreeMode, Pagination, Navigation } from "swiper/modules";

const Style = styled.section`
  padding-top: 72px;
  overflow: hidden;
  /* @media only screen and (max-width: 576px) and (min-width: 320px) {
    padding-top: 50px;
  } */
`;
const Brands = ({ title }) => {
  const [lengthh, setLengthh] = useState();
  const [brands, setBrands] = useState([]);
  const [firstHalf, setFirstHalf] = useState([]);
  const [secondHalf, setSecondHalf] = useState([]);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    getBrands();
  }, [i18n.language]);

  const getBrands = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get(`api/v1/partners`)
      .then((res) => {
        if (res.status === 200) {
          let data = res?.data?.data;
          setLengthh(data?.length);
          const urlHash = window.location.hash;
          if (urlHash.substring(1) === "clients") {
            setTimeout(() => {
              if (urlHash.length) {
                const element = document.getElementById(urlHash.substring(1));
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }, 80);
          }
          if (data?.length > 12) {
            const middleIndex = Math.ceil(data.length / 2);

            const firstHalf = data.splice(0, middleIndex);
            const secondHalf = data.splice(-middleIndex);

            setFirstHalf(firstHalf);
            setSecondHalf(secondHalf);
          } else {
            setBrands(data);
          }
        }
      })
      .catch((error) => {
        setBrands([]);
        setFirstHalf([]);
        setSecondHalf([]);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  const settings = {
    dots: false,
    nav: false,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Style id="clients">
        <div className="container">
          <p className="title_app" style={{ marginBottom: "36px" }}>
            {title}
          </p>
        </div>

        <div className="brand_test_main">
          {brands.map((item, index) => {
            return (
              <div key={index}>
                <img src={item?.image} className="brand_test" alt="" />
              </div>
            );
          })}
          {brands.map((item, index) => {
            return (
              <div key={index}>
                <img src={item?.image} className="brand_test" alt="" />
              </div>
            );
          })}
        </div>
      </Style>
    </>
  );
};

export default Brands;
