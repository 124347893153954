import React, { useState, useEffect, useRef } from "react";
import WaveSurfer from "wavesurfer.js";
import styled from "styled-components";
import Play from "../img/svg/play-circle-fill.svg";
import Pause from "../img/svg/pause-circle-fill.svg";
const WaveSurferWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 16px;
  background: #f7fbfe;
  padding: 12px;
  height: 40px;
  overflow: hidden;
  position: relative;
  .linetop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 12px;

    background: #f7fbfe;
    z-index: 999;
  }
  .linebottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 12px;

    background: #f7fbfe;
    z-index: 999;
  }
`;

const Waveform = ({ audio, playSlider, pauseSlider }) => {
  const containerRef = useRef();
  const waveSurferRef = useRef({
    isPlaying: false,
  });
  const [isPlaying, toggleIsPlaying] = useState(false);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    const waveSurfer = WaveSurfer.create({
      container: containerRef.current,
      responsive: true,
      barWidth: 2,
      barHeight: 1.8,
      cursorWidth: 0,
      waveColor: "#7C8B9F",
      progressColor: "#0193DE",
    });
    waveSurfer.load(audio);

    waveSurfer.on("ready", () => {
      waveSurferRef.current = waveSurfer;
    });

    waveSurfer.on("finish", () => {
      setToggle(false);
      playSlider();
    });

    return () => {
      waveSurfer.destroy();
    };
  }, [audio]);

  const waveClick = () => {
    waveSurferRef.current.playPause();
    toggleIsPlaying(waveSurferRef.current.isPlaying());
    setToggle(!toggle);
    console.log(toggle);
    if (toggle == false) {
      pauseSlider();
    } else {
      playSlider();
    }
  };

  return (
    <WaveSurferWrap>
      <div className="linetop"></div>
      <div className="linebottom"></div>
      <div
        className="cursor"
        onClick={() => {
          waveClick();
        }}
        type="button"
      >
        {/* {isPlaying ? <img src={Pause} alt="" /> : <img src={Play} alt="" />} */}
        {toggle === true ? (
          <img src={Pause} alt="" />
        ) : (
          <img src={Play} alt="" />
        )}
      </div>
      <div style={{ width: "100%", marginLeft: "8px" }} ref={containerRef} />
    </WaveSurferWrap>
  );
};

export default Waveform;
