import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import CollapseContent from "../../components/CollapseContent";
import { BreadCrumb } from "../../components/GlobalStyle";
import Footer from "../../layout/Footer";
import DotsMenu from "../../components/DotsMenu";

const FaqSection = styled.div`
  min-height: 500px;
`;
const Faq = () => {
  return (
    <>
      <FaqSection>
        <div className="container">
          <BreadCrumb>
            <Link to="/">Главная</Link>
            <span className="line"> / </span>
            <span>FAQ</span>
          </BreadCrumb>
        </div>
        <DotsMenu />
        <CollapseContent question="question" />
      </FaqSection>
    </>
  );
};

export default Faq;
