import { styled } from "styled-components";

export const BreadCrumb = styled.div`
  margin-top: 21px;
  color: #7c8b9f !important;
  font-family: "Geologica-Regular", sans-serif;
  font-size: 14px;
  line-height: normal;
  a {
    color: #7c8b9f !important;
  }
`;
