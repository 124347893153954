import React from "react";
import { styled } from "styled-components";
import CollapseContent from "../../components/CollapseContent";

const Main = styled.div`
  .sections {
    width: 100%;
    &:nth-child(1) {
      background-color: red;
    }
    &:nth-child(2) {
      background-color: #00fff2;
    }
    &:nth-child(3) {
      background-color: blue;
    }
    &:nth-child(4) {
      background-color: green;
    }
    &:nth-child(5) {
      background-color: orange;
    }
  }
`;
const Test = () => {
  return (
    <Main>
      <section className="sections" id="comp">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum
        nesciunt alias suscipit? Nisi veritatis expedita illo quod, asperiores
        alias molestiae obcaecati libero reprehenderit veniam sed laudantium! In
        dignissimos distinctio quo. Incidunt vel quibusdam consequuntur
        laboriosam minus labore, debitis suscipit consequatur inventore
        provident quos officiis, aut enim, soluta doloribus ea voluptatum
        delectus. Libero animi nisi inventore molestias quis a ullam ea.
        Voluptas repellendus a ex ratione adipisci impedit voluptatibus incidunt
        error optio est, labore, magnam doloremque amet aliquam minima debitis
        voluptatem! Laborum assumenda voluptatum aspernatur mollitia quos culpa,
        libero aut suscipit? Itaque, corrupti illo. Dicta et voluptatem nihil,
        iure quae natus ex corporis delectus sint, fugiat eaque error odio eos
        aspernatur suscipit maiores aut, tempora similique non molestiae sed
        quaerat earum. Impedit tempora unde possimus nihil maxime distinctio
        vitae consequatur atque aut, officia molestiae, ullam quis explicabo
        veritatis animi id neque exercitationem, vel iste in ab repudiandae
        rerum dolore consequuntur. Qui. Laudantium quia reprehenderit esse neque
        illum consequatur distinctio eveniet nihil autem, ea quam sunt accusamus
        fuga odit soluta incidunt unde, consequuntur quas maiores dolorem,
        doloremque eos ullam iusto. Et, nisi? Mollitia, quisquam accusamus!
        Aspernatur magnam architecto dolorem quam amet perspiciatis culpa
        accusantium, consequuntur fuga sapiente obcaecati et porro eveniet iure
        laborum deleniti totam nam. Ex tempora rerum debitis et. Ipsum. Porro
        vero obcaecati sed distinctio iste dicta enim animi, temporibus voluptas
        quo dolores maxime ipsum quibusdam eveniet a aliquid impedit sunt dolor
        quisquam. Quia inventore, nisi iusto dolor labore aspernatur. Sapiente
        placeat ea laboriosam quia natus cum sunt veniam quidem maiores,
        distinctio fugiat amet soluta esse officia numquam quisquam obcaecati
        delectus consequuntur ratione blanditiis, recusandae facilis. Pariatur
        recusandae eaque adipisci? Sint corporis aspernatur beatae consequatur
        reiciendis id voluptates hic. Sed ducimus quis nostrum incidunt voluptas
        ullam labore, qui, aliquam veritatis consequuntur vitae culpa
        repudiandae obcaecati molestiae reiciendis cum aut rerum.
      </section>
      <section className="sections" id="servicess">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis ullam,
        repellat praesentium placeat libero iusto assumenda, in aperiam sed ut
        voluptates minima perspiciatis exercitationem debitis tenetur facere
        obcaecati, soluta dolore. Ducimus illo impedit error nihil magni, illum
        officiis totam consequuntur ab. Ex a omnis aut ea rem rerum sapiente
        quasi molestiae sunt reprehenderit, autem blanditiis praesentium,
        beatae, perspiciatis tenetur vel? Iure ipsum aliquid vitae est similique
        architecto! Dignissimos omnis ullam veniam sunt? Repellat explicabo, ut
        minima nam velit culpa accusamus qui quam reprehenderit dignissimos
        pariatur laborum? Asperiores nemo eligendi ratione! Omnis, labore
        voluptas eum maxime impedit deleniti, temporibus eveniet dolore qui
        saepe eos facere fuga quidem, quaerat amet deserunt fugit asperiores
        officiis consequuntur. Debitis dolor est exercitationem architecto
        cupiditate harum! Tempora quia voluptatem totam rerum atque. Ducimus
        numquam incidunt possimus praesentium assumenda, modi omnis libero
        dignissimos sequi vel esse minus, quod eos quisquam! Voluptates illum
        eos facere quae fugit a. Magnam doloremque repellat quas impedit quod
        totam ex perferendis reiciendis omnis veritatis officia delectus
        excepturi sed enim hic fugit soluta voluptatum eveniet eaque, et
        recusandae magni voluptates! Ipsam, doloremque quam. Quas in dolorum
        sit, totam vitae eius sed architecto. Molestiae, molestias! Iure
        voluptatibus et consectetur, quo harum expedita exercitationem? Hic
        autem ab a, maiores consectetur eum officiis consequatur sunt
        perferendis. Enim dolorum, dolores maxime porro aut alias doloribus
        voluptas molestiae nostrum eum, nisi quae error amet beatae voluptatem
        libero excepturi fugit labore consequuntur quam in quo debitis modi
        quaerat! Aut! Eaque assumenda quos autem odio soluta ab culpa libero.
        Suscipit dicta delectus, dolores cupiditate fugiat id natus quisquam
        distinctio ipsa, nihil qui possimus illum accusamus pariatur, magni quia
        rerum dolor. Eaque quam provident, cum nesciunt perspiciatis at
        praesentium explicabo iure magnam iusto necessitatibus facere temporibus
        autem natus excepturi animi ratione, saepe facilis nihil a? Tempora,
        distinctio minima? Deserunt, dolorem illo! Qui laudantium, voluptate
        dignissimos aliquid impedit eius? Quaerat architecto deleniti ab ducimus
        dolores quas doloremque vero consectetur, vitae a nihil, hic, voluptas
        reprehenderit temporibus est exercitationem consequatur nulla molestias.
        Nulla. Necessitatibus dignissimos nesciunt natus nostrum aut.
        Repellendus aliquam accusamus sint mollitia iste repellat reprehenderit
        labore dolor sunt praesentium, dicta recusandae nobis esse consequatur,
        at non, eum consequuntur quisquam temporibus delectus! Consectetur
        veritatis ad expedita ipsa eveniet ullam aspernatur labore soluta, sint
        inventore voluptas fuga repellat assumenda modi vel voluptatum velit
        nesciunt eum commodi provident? Labore quis distinctio odio non. Quis.
        Mollitia numquam quo aliquid odit exercitationem ab vero illo quam,
        recusandae laborum cupiditate at sequi repellat illum rem odio tempora!
        Dignissimos, quidem repellat aperiam iure inventore pariatur eaque
        tenetur libero? Voluptatem enim consectetur et, magnam fuga non facere,
        magni totam dignissimos quam, repudiandae doloribus ipsam? Tempora
        laborum nostrum ad soluta, provident error deleniti molestiae deserunt,
        quae iusto adipisci aliquam magnam! Doloribus eaque itaque deserunt
        explicabo tempora iusto beatae quisquam numquam, quas eos totam, ex
        laborum? Nesciunt, harum voluptatum deserunt explicabo similique, quam
        rerum omnis voluptas impedit molestias dolorem commodi laudantium?
        Dolores quibusdam incidunt harum sint neque illo corrupti obcaecati a
        quaerat perspiciatis assumenda, voluptatibus quos asperiores blanditiis
        atque officia ullam fuga. Nobis id non, sapiente cumque exercitationem
        recusandae quam deserunt! Quidem corrupti veniam iste libero doloremque
        aut labore inventore ducimus laboriosam amet, voluptas quisquam
        praesentium iure corporis ullam numquam asperiores consequuntur non,
        nulla nostrum, molestiae tempore sunt incidunt. Accusamus, optio.
        Officia ducimus repudiandae fugit! Nam aspernatur, perferendis saepe
        beatae eum porro iste repudiandae sit voluptates sequi! Quasi aut ab sit
        molestias similique voluptate officiis! Hic rem repellat nihil minima
        cupiditate. Quisquam, quas. Sed quidem officia ipsa? Quis maiores
        ratione quas explicabo alias, voluptas voluptatem dicta quisquam
        consequuntur, nihil nemo corrupti. Sed ad possimus ullam esse
        accusantium voluptatum temporibus officiis eius.
      </section>
      <section className="sections" id="clients">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus ab
        iure laudantium voluptate adipisci, nam sed. Reprehenderit labore quod
        qui dolorum nihil, non eum architecto. Praesentium culpa maxime placeat
        laudantium! Eum tempore provident beatae repellendus, sit nobis numquam
        dicta, quidem assumenda nemo voluptatibus nihil ab a quaerat. Dicta at
        tempore nulla sapiente? Dolores, tempore. Voluptates itaque modi
        aspernatur quaerat facilis. Dicta cum saepe veniam laudantium quia nulla
        quaerat praesentium ipsum modi, dolorum similique sunt magni culpa ab
        id, necessitatibus dolores dignissimos corrupti iste rerum quos atque
        eveniet excepturi debitis. Reiciendis. Magni dicta fugit nemo fuga
        minima sint repellat. Quos nihil magni ratione excepturi et odio autem
        nam soluta in dolor. Ipsum nam neque aut corrupti veniam rerum
        cupiditate voluptatum asperiores! Perspiciatis, neque? Impedit
        consequuntur debitis sapiente cum, nesciunt vel! Quia accusamus error
        cum consectetur architecto quae inventore perspiciatis a ea saepe enim,
        rem earum, cumque commodi non aperiam, pariatur aspernatur! Atque natus
        sint, non dicta pariatur assumenda autem amet alias quae iusto adipisci.
        Magni beatae ut voluptatem assumenda, consequatur sapiente
        exercitationem dolorum alias explicabo perspiciatis, at nulla, porro
        cumque dicta! Perferendis similique maxime possimus ipsam! Harum dolor
        natus earum? Pariatur, eos corrupti velit numquam suscipit nostrum
        voluptates cumque ratione! Soluta unde voluptates iste iusto ex
        suscipit, eum illo dolor dolorum? Numquam nobis beatae perferendis
        officia corrupti, sequi porro, ex, totam architecto ea ullam
        consequuntur quaerat! Qui temporibus accusantium repellendus architecto
        sit, deleniti ad, maiores quis, facilis laudantium aperiam ipsam
        cupiditate. A suscipit illo molestiae quam ipsa, quisquam eligendi
        placeat, temporibus doloremque error perspiciatis? Consequatur expedita
        velit perspiciatis minima et nemo omnis perferendis tempora sapiente
        deleniti. Consequatur velit rem delectus excepturi. Voluptate, pariatur
        commodi voluptatibus eos maxime impedit eligendi provident tempore ex
        enim debitis eum repudiandae nihil. Possimus magni quibusdam dolores
        dolore autem illo deleniti qui totam eveniet ab. Dolores, aperiam. Ipsum
        voluptatem nostrum laudantium a aut, eum sapiente necessitatibus atque
        eaque consectetur incidunt nisi. Vitae, sit! Modi laudantium itaque
        nostrum optio accusantium ab esse, nihil est. Harum odit praesentium
        corporis? Sequi temporibus recusandae maxime ea, sunt excepturi
        reprehenderit rerum a alias soluta quia unde enim ratione minus pariatur
        provident quas officia et distinctio nisi repellat modi perferendis.
        Eligendi, ipsam quisquam. Eligendi eos, blanditiis iusto maxime, animi
        libero fugit perferendis quasi natus ab a. Aperiam, numquam soluta
        exercitationem quas accusantium hic vero cum assumenda mollitia deleniti
        nihil quia iure velit doloribus! Quam aspernatur cum eum cumque
        repellat! Libero excepturi suscipit consequuntur nam ipsum ex illo
        quisquam id. Perferendis recusandae labore molestias? Eligendi, ipsam?
        Sit exercitationem ullam, fugit quidem quis in soluta! Temporibus,
        veritatis suscipit quis consequatur mollitia numquam tempora amet
        voluptate non accusamus autem dignissimos corrupti possimus ipsum quasi.
        Ea perspiciatis laboriosam ab magni qui omnis odio doloremque eos eum
        eaque! Eum voluptatum autem quasi dignissimos. Dolore quos blanditiis
        ea. Soluta, quas dolorem voluptatibus sapiente hic molestias. Voluptates
        cupiditate atque perferendis aperiam tempore laborum blanditiis.
        Delectus corrupti doloremque possimus error quas? Doloremque adipisci
        officia aliquid quis rerum, illo at voluptatibus. Officia doloremque
        eaque ducimus maxime tempore optio esse, ipsam ipsum pariatur accusamus
        nihil, sint quos qui unde dolores quia totam suscipit. Repellat,
        eligendi quas. Sapiente aliquam dolores velit eligendi, rerum fuga amet
        nulla ut asperiores reiciendis nisi aut, totam voluptates voluptatum.
        Enim deleniti quae aperiam dignissimos obcaecati ipsa magnam quod
        praesentium! A soluta dicta deleniti dolorem enim ex quod rem
        accusantium? Laudantium qui esse perferendis nesciunt dignissimos ipsum
        odit eveniet voluptatem inventore exercitationem commodi culpa corrupti
        laboriosam, autem officiis voluptates rerum. Deserunt, necessitatibus?
        Velit, ipsa? Amet, quisquam accusantium nemo iure rem quae repellat
        ducimus harum iusto commodi veritatis maiores qui laudantium dignissimos
        dolore blanditiis alias rerum ipsam dicta aut eos numquam?
        Exercitationem ratione vel modi praesentium culpa consequatur at ut
        repellat unde illum eius explicabo consectetur voluptatum delectus dolor
        laudantium assumenda quod minus eaque, doloribus natus. Unde quia magni
        repellendus animi. Architecto tempora provident minus sint impedit?
        Deserunt, officiis dolorum nostrum ullam perferendis suscipit eveniet
        animi porro at. Vero officia consequatur magnam. Deleniti doloribus
        nobis consequatur ea voluptas quos! Totam, nam. Enim ullam iusto odit
        fugiat praesentium? Laudantium, laborum. Officia sequi enim eius qui,
        nobis placeat debitis beatae possimus voluptates modi ducimus facere
        dolor quia magni iste fuga corrupti nisi nemo. Doloribus, numquam sit
        cum nam officiis iure molestias quam aperiam natus id! Harum earum
        reiciendis accusantium est dolorem odio, odit rem esse facere laboriosam
        deleniti sit ad ducimus inventore impedit. Vero atque est ipsam
        molestiae quis dolorem quos et iste! Itaque aliquid nam delectus omnis
        similique! Facere praesentium sunt esse optio illum id. Dolorem quod ad
        earum enim excepturi ratione! Nulla, optio exercitationem pariatur
        voluptatum dolor suscipit dolorum iste qui fugit, aliquid quis quidem?
        Dolores natus error alias explicabo reprehenderit dolor deleniti enim
        perferendis ad, labore rem voluptates at obcaecati? Sed error cum unde
        hic distinctio quasi eveniet impedit id facilis earum! Nesciunt expedita
        ipsum necessitatibus dicta suscipit optio dolore eos, a molestiae eius
        libero neque corrupti eaque facilis voluptatem? A obcaecati minus,
        blanditiis, quis minima ea amet quae nobis qui eum nostrum saepe debitis
        dignissimos quibusdam quidem illo id nesciunt autem sunt voluptate
        laudantium! Totam doloribus nemo vero iste. Cum id sit impedit iste
        nulla. Nam numquam non doloremque! Dignissimos suscipit magnam dolores
        eos! Unde voluptatem doloremque ad totam modi nisi, eligendi provident
        voluptates inventore quibusdam soluta possimus laboriosam. Molestiae aut
        perspiciatis animi sit aperiam excepturi quidem doloribus magnam autem
        enim? Quos natus fuga doloribus corporis culpa, recusandae sed
        veritatis? Mollitia itaque a eos provident, vero nostrum quia neque?
      </section>
      <section className="sections" id="formm">
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestiae
        natus magni tempore ut, officiis a id fugit quia voluptatum unde dolorem
        tempora? Voluptas quis suscipit necessitatibus, magnam nostrum iusto a.
        Eos suscipit natus corrupti impedit, laboriosam quaerat aliquam
        necessitatibus! Nisi explicabo suscipit mollitia asperiores? Cupiditate
        officia enim deleniti distinctio obcaecati architecto! Magni perferendis
        a totam doloremque saepe inventore libero reiciendis? Ducimus natus, sit
        assumenda beatae sint perspiciatis perferendis debitis. Saepe totam
        animi odit! Consequatur aspernatur voluptatum vero. Dicta pariatur
        dolorem architecto fugit, inventore quidem harum nulla possimus, quas
        laborum nemo? Adipisci quibusdam libero, quia beatae odit perferendis
        praesentium cupiditate, commodi accusamus fugiat id sequi nemo ipsam
        porro vel, et facilis ducimus omnis earum nulla optio sint. Dolorum aut
        fugiat assumenda! Placeat quisquam itaque minima voluptate assumenda
        non, corrupti exercitationem cumque natus nisi, nesciunt doloremque
        atque quod tenetur a repellat culpa neque. Repudiandae vel tempore
        cumque at. Laborum quidem dicta repellat! Hic, facilis suscipit minus
        delectus laborum consequuntur quos nobis praesentium optio, voluptates
        neque reprehenderit earum eligendi porro voluptas quidem temporibus
        architecto fugit accusamus quia voluptate mollitia debitis dolores
        dolorum. Nisi! Eveniet a distinctio impedit fugit, sint maiores! Vitae
        soluta iure officiis, laborum minus omnis cum iste asperiores accusamus
        excepturi aspernatur perferendis, commodi nemo iusto exercitationem
        maiores eum dolorem sit? Rem. Hic iure esse qui ad modi, dolores eius
        error minima accusantium maxime quae aliquid quisquam, soluta quia
        harum, libero perferendis blanditiis et quasi sit sint magnam incidunt!
        Veniam, esse non? Veniam quo, libero voluptatum debitis ducimus
        voluptates nostrum, blanditiis tempore dolorum impedit repellat eos
        assumenda sint sed cum iure. Voluptatum rerum aspernatur voluptatibus
        praesentium eius inventore, sit incidunt iusto mollitia. In illo ipsum
        officia corrupti ipsam voluptates fugiat eligendi, quo qui
        exercitationem tenetur inventore aliquam optio ad rem delectus molestias
        est eum ratione. Nulla quia quae quos fugiat sapiente? Minus! Alias iste
        magni a tenetur illo in odio eos consequatur nisi sint. A, numquam
        saepe? Autem enim, cum, voluptate fuga ducimus officiis adipisci
        recusandae excepturi dolor tempore eum vero facilis? Maiores aliquam
        repellat culpa adipisci esse voluptatum ducimus sed, reiciendis, aliquid
        nemo animi, mollitia omnis? Culpa totam adipisci, architecto repellat
        quaerat, pariatur, quidem mollitia nihil ab magni ipsam quam iure. Natus
        enim minima dolorem qui aut iusto! Doloribus ipsa corporis culpa,
        facilis nobis at dolores optio possimus ullam assumenda veritatis
        aperiam, rem doloremque explicabo magni perspiciatis repudiandae
        consequuntur amet ratione. Aperiam architecto quam expedita eos adipisci
        laborum fugit. Totam, in dignissimos unde sapiente facilis deserunt
        nihil doloribus laborum quibusdam, voluptate earum illum quidem, quis
        corporis vel culpa id quas. Deserunt. Voluptatum quis repudiandae ipsam
        iure nihil rem dolorum esse, culpa nostrum distinctio aperiam dolores
        eaque sed eveniet vel libero. Debitis nesciunt quisquam fugiat explicabo
        totam eligendi iusto accusamus consequatur maiores. Neque nobis vel vero
        fugit asperiores, ducimus velit architecto in repudiandae ratione
        distinctio consequuntur sapiente amet officiis at labore laudantium
        officia pariatur animi dolor. Asperiores culpa ipsum ab assumenda et.
        Vel provident vitae corporis iusto sit, laudantium ipsam possimus ipsum
        molestiae fugiat commodi neque ipsa aliquam a? Rem iure non incidunt
        modi? Illo incidunt accusantium totam nam eos aliquam exercitationem.
        Iusto veritatis itaque reprehenderit dignissimos, nulla, dolores culpa
        nostrum numquam, sit eius nobis quibusdam nam distinctio dicta sed.
        Molestiae voluptatibus velit harum unde similique sit eum dolores facere
        voluptatum officiis. Veritatis dolores molestias voluptate eum ut enim,
        at quae consectetur quia voluptatum autem fugiat ab labore pariatur
        totam impedit modi vel libero consequatur? Velit consectetur cum tempora
        ad quia? Consequatur. Delectus eaque sit tenetur pariatur, nihil esse
        harum corrupti, ipsa unde, numquam similique veniam fuga distinctio.
        Ipsam aliquam nemo exercitationem. Molestiae dolores laboriosam
        veritatis alias amet nemo animi incidunt repellat. A atque, quo voluptas
        aliquam voluptatem assumenda eligendi ab nesciunt culpa laboriosam,
        cumque accusantium suscipit facilis. Ipsum maiores voluptas commodi
        asperiores repudiandae cum, quod exercitationem explicabo autem ea
        quidem est. Adipisci modi explicabo odio, ducimus, dolorem maxime
        similique iusto quisquam commodi aspernatur nihil consequuntur et optio,
        perspiciatis tempore cupiditate cumque? Error culpa autem accusantium in
        eos ullam nulla natus similique. Maxime neque, commodi voluptate
        distinctio vitae culpa? Magni vel ad itaque facere eos totam,
        consectetur adipisci animi dolor error necessitatibus perspiciatis
        molestias velit inventore odio, nemo unde modi aperiam? Placeat?
        Quisquam eaque eos animi repudiandae numquam est ullam excepturi fuga
        incidunt nesciunt obcaecati veniam repellendus labore hic tempore cumque
        veritatis eligendi ea, nihil sit blanditiis aut ipsum sequi alias! Eos?
        Minus distinctio sint, in dolore voluptatem beatae, reprehenderit
        adipisci nemo aliquid, non accusamus accusantium? Corporis quos
        perspiciatis iste voluptates quo repudiandae ipsam? Consectetur, facere
        quidem fuga laborum consequuntur expedita voluptatibus. Asperiores ipsam
        aperiam commodi nisi reiciendis illum optio a laudantium fugiat
        officiis. Qui accusantium numquam incidunt, illo expedita delectus quasi
        ea id cumque dolorum culpa est tempora provident quisquam magni. Ad
        molestias nulla distinctio quae voluptatem? Asperiores, aperiam natus
        est, sapiente consequuntur id, reprehenderit sint vel voluptate
        cupiditate facere pariatur inventore corrupti. Quibusdam iste vero
        suscipit eaque, deserunt ex harum. A modi nisi quam suscipit sit
        inventore odit repellendus iste debitis blanditiis eum eligendi, aperiam
        ipsam. Ad, quis saepe inventore dolores labore distinctio quae cum.
        Commodi error aliquid autem totam. Quas, distinctio consectetur! A
        perspiciatis natus est deleniti dolores ad cum, sed itaque et voluptate
        quam, saepe, maiores nihil sunt aut modi laborum eos distinctio
        doloremque sit porro vitae alias! Consectetur iste, ratione iusto fuga
        ipsa distinctio incidunt quisquam eveniet sunt nihil hic perspiciatis
        recusandae, velit reiciendis animi placeat eaque a in molestias! Maiores
        sunt dolorem, dolore aliquid repellendus ipsum! Placeat neque qui
        consequatur amet nihil natus debitis soluta, sequi ipsa officiis
        deserunt veniam molestias quibusdam iusto deleniti dicta totam unde odio
        laborum quisquam tempora! Obcaecati sapiente autem magni tempora? Illo
        vitae nulla ex, expedita dolore doloremque recusandae architecto?
        Quaerat doloribus harum nobis id tempore officiis quos! Sit fugit,
        laudantium totam sunt distinctio dicta quo nihil adipisci dolor facilis
        optio. Necessitatibus, minima sunt dolore eligendi adipisci itaque. Nam
        magnam mollitia temporibus nulla. Magni ullam quaerat voluptates
        asperiores, ipsam enim harum nemo modi dicta laudantium illum facere,
        numquam commodi reprehenderit dolores! Voluptates fugit harum placeat
        facere. Eveniet provident architecto sint, ut placeat labore ratione,
        assumenda eos natus accusantium explicabo dolorem accusamus. Minus
        laboriosam impedit esse distinctio obcaecati odit, quisquam maiores
        fugiat! Ipsa itaque alias, nam perspiciatis odio eaque debitis facilis
        cumque dignissimos est reprehenderit non vel architecto culpa maxime
        porro reiciendis delectus veritatis in, sit maiores minima. Minus
        veritatis cupiditate enim. Inventore quam tempore ex cumque nihil
        necessitatibus, corrupti officia optio aperiam voluptatum ipsam
        aspernatur harum accusantium. Eum perferendis ratione nostrum, rem in
        eos optio quae ipsum exercitationem laudantium quo voluptatem? Debitis
        totam hic dolore aperiam minima, voluptas dolor natus inventore
        blanditiis nemo temporibus eum possimus magnam nulla eveniet quia quod.
        Iste, unde sequi quia quaerat eaque blanditiis explicabo! Iusto,
        officia. Porro deleniti consequatur illo eum enim vero pariatur eius!
        Tenetur a deserunt harum necessitatibus architecto quo itaque cupiditate
        voluptatibus dolorem facere explicabo eaque, placeat fuga culpa error,
        quam atque ratione? Quas doloribus voluptas impedit illo suscipit, vitae
        beatae illum tempora voluptatum ex nostrum iusto quam! Aspernatur
        praesentium vel, exercitationem at magni doloribus sint tempore, nisi
        deleniti atque ullam. Illum, ea. Voluptate architecto fugit error
        perferendis. Quas, veniam suscipit debitis, dolores quia asperiores odio
        iste nemo neque ratione nam sit laborum deserunt ipsam culpa impedit
        molestiae voluptatem temporibus? Facere, temporibus nobis!
      </section>
      <section className="sections" id="formm">
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestiae
        natus magni tempore ut, officiis a id fugit quia voluptatum unde dolorem
        tempora? Voluptas quis suscipit necessitatibus, magnam nostrum iusto a.
        Eos suscipit natus corrupti impedit, laboriosam quaerat aliquam
        necessitatibus! Nisi explicabo suscipit mollitia asperiores? Cupiditate
        officia enim deleniti distinctio obcaecati architecto! Magni perferendis
        a totam doloremque saepe inventore libero reiciendis? Ducimus natus, sit
        assumenda beatae sint perspiciatis perferendis debitis. Saepe totam
        animi odit! Consequatur aspernatur voluptatum vero. Dicta pariatur
        dolorem architecto fugit, inventore quidem harum nulla possimus, quas
        laborum nemo? Adipisci quibusdam libero, quia beatae odit perferendis
        praesentium cupiditate, commodi accusamus fugiat id sequi nemo ipsam
        porro vel, et facilis ducimus omnis earum nulla optio sint. Dolorum aut
        fugiat assumenda! Placeat quisquam itaque minima voluptate assumenda
        non, corrupti exercitationem cumque natus nisi, nesciunt doloremque
        atque quod tenetur a repellat culpa neque. Repudiandae vel tempore
        cumque at. Laborum quidem dicta repellat! Hic, facilis suscipit minus
        delectus laborum consequuntur quos nobis praesentium optio, voluptates
        neque reprehenderit earum eligendi porro voluptas quidem temporibus
        architecto fugit accusamus quia voluptate mollitia debitis dolores
        dolorum. Nisi! Eveniet a distinctio impedit fugit, sint maiores! Vitae
        soluta iure officiis, laborum minus omnis cum iste asperiores accusamus
        excepturi aspernatur perferendis, commodi nemo iusto exercitationem
        maiores eum dolorem sit? Rem. Hic iure esse qui ad modi, dolores eius
        error minima accusantium maxime quae aliquid quisquam, soluta quia
        harum, libero perferendis blanditiis et quasi sit sint magnam incidunt!
        Veniam, esse non? Veniam quo, libero voluptatum debitis ducimus
        voluptates nostrum, blanditiis tempore dolorum impedit repellat eos
        assumenda sint sed cum iure. Voluptatum rerum aspernatur voluptatibus
        praesentium eius inventore, sit incidunt iusto mollitia. In illo ipsum
        officia corrupti ipsam voluptates fugiat eligendi, quo qui
        exercitationem tenetur inventore aliquam optio ad rem delectus molestias
        est eum ratione. Nulla quia quae quos fugiat sapiente? Minus! Alias iste
        magni a tenetur illo in odio eos consequatur nisi sint. A, numquam
        saepe? Autem enim, cum, voluptate fuga ducimus officiis adipisci
        recusandae excepturi dolor tempore eum vero facilis? Maiores aliquam
        repellat culpa adipisci esse voluptatum ducimus sed, reiciendis, aliquid
        nemo animi, mollitia omnis? Culpa totam adipisci, architecto repellat
        quaerat, pariatur, quidem mollitia nihil ab magni ipsam quam iure. Natus
        enim minima dolorem qui aut iusto! Doloribus ipsa corporis culpa,
        facilis nobis at dolores optio possimus ullam assumenda veritatis
        aperiam, rem doloremque explicabo magni perspiciatis repudiandae
        consequuntur amet ratione. Aperiam architecto quam expedita eos adipisci
        laborum fugit. Totam, in dignissimos unde sapiente facilis deserunt
        nihil doloribus laborum quibusdam, voluptate earum illum quidem, quis
        corporis vel culpa id quas. Deserunt. Voluptatum quis repudiandae ipsam
        iure nihil rem dolorum esse, culpa nostrum distinctio aperiam dolores
        eaque sed eveniet vel libero. Debitis nesciunt quisquam fugiat explicabo
        totam eligendi iusto accusamus consequatur maiores. Neque nobis vel vero
        fugit asperiores, ducimus velit architecto in repudiandae ratione
        distinctio consequuntur sapiente amet officiis at labore laudantium
        officia pariatur animi dolor. Asperiores culpa ipsum ab assumenda et.
        Vel provident vitae corporis iusto sit, laudantium ipsam possimus ipsum
        molestiae fugiat commodi neque ipsa aliquam a? Rem iure non incidunt
        modi? Illo incidunt accusantium totam nam eos aliquam exercitationem.
        Iusto veritatis itaque reprehenderit dignissimos, nulla, dolores culpa
        nostrum numquam, sit eius nobis quibusdam nam distinctio dicta sed.
        Molestiae voluptatibus velit harum unde similique sit eum dolores facere
        voluptatum officiis. Veritatis dolores molestias voluptate eum ut enim,
        at quae consectetur quia voluptatum autem fugiat ab labore pariatur
        totam impedit modi vel libero consequatur? Velit consectetur cum tempora
        ad quia? Consequatur. Delectus eaque sit tenetur pariatur, nihil esse
        harum corrupti, ipsa unde, numquam similique veniam fuga distinctio.
        Ipsam aliquam nemo exercitationem. Molestiae dolores laboriosam
        veritatis alias amet nemo animi incidunt repellat. A atque, quo voluptas
        aliquam voluptatem assumenda eligendi ab nesciunt culpa laboriosam,
        cumque accusantium suscipit facilis. Ipsum maiores voluptas commodi
        asperiores repudiandae cum, quod exercitationem explicabo autem ea
        quidem est. Adipisci modi explicabo odio, ducimus, dolorem maxime
        similique iusto quisquam commodi aspernatur nihil consequuntur et optio,
        perspiciatis tempore cupiditate cumque? Error culpa autem accusantium in
        eos ullam nulla natus similique. Maxime neque, commodi voluptate
        distinctio vitae culpa? Magni vel ad itaque facere eos totam,
        consectetur adipisci animi dolor error necessitatibus perspiciatis
        molestias velit inventore odio, nemo unde modi aperiam? Placeat?
        Quisquam eaque eos animi repudiandae numquam est ullam excepturi fuga
        incidunt nesciunt obcaecati veniam repellendus labore hic tempore cumque
        veritatis eligendi ea, nihil sit blanditiis aut ipsum sequi alias! Eos?
        Minus distinctio sint, in dolore voluptatem beatae, reprehenderit
        adipisci nemo aliquid, non accusamus accusantium? Corporis quos
        perspiciatis iste voluptates quo repudiandae ipsam? Consectetur, facere
        quidem fuga laborum consequuntur expedita voluptatibus. Asperiores ipsam
        aperiam commodi nisi reiciendis illum optio a laudantium fugiat
        officiis. Qui accusantium numquam incidunt, illo expedita delectus quasi
        ea id cumque dolorum culpa est tempora provident quisquam magni. Ad
        molestias nulla distinctio quae voluptatem? Asperiores, aperiam natus
        est, sapiente consequuntur id, reprehenderit sint vel voluptate
        cupiditate facere pariatur inventore corrupti. Quibusdam iste vero
        suscipit eaque, deserunt ex harum. A modi nisi quam suscipit sit
        inventore odit repellendus iste debitis blanditiis eum eligendi, aperiam
        ipsam. Ad, quis saepe inventore dolores labore distinctio quae cum.
        Commodi error aliquid autem totam. Quas, distinctio consectetur! A
        perspiciatis natus est deleniti dolores ad cum, sed itaque et voluptate
        quam, saepe, maiores nihil sunt aut modi laborum eos distinctio
        doloremque sit porro vitae alias! Consectetur iste, ratione iusto fuga
        ipsa distinctio incidunt quisquam eveniet sunt nihil hic perspiciatis
        recusandae, velit reiciendis animi placeat eaque a in molestias! Maiores
        sunt dolorem, dolore aliquid repellendus ipsum! Placeat neque qui
        consequatur amet nihil natus debitis soluta, sequi ipsa officiis
        deserunt veniam molestias quibusdam iusto deleniti dicta totam unde odio
        laborum quisquam tempora! Obcaecati sapiente autem magni tempora? Illo
        vitae nulla ex, expedita dolore doloremque recusandae architecto?
        Quaerat doloribus harum nobis id tempore officiis quos! Sit fugit,
        laudantium totam sunt distinctio dicta quo nihil adipisci dolor facilis
        optio. Necessitatibus, minima sunt dolore eligendi adipisci itaque. Nam
        magnam mollitia temporibus nulla. Magni ullam quaerat voluptates
        asperiores, ipsam enim harum nemo modi dicta laudantium illum facere,
        numquam commodi reprehenderit dolores! Voluptates fugit harum placeat
        facere. Eveniet provident architecto sint, ut placeat labore ratione,
        assumenda eos natus accusantium explicabo dolorem accusamus. Minus
        laboriosam impedit esse distinctio obcaecati odit, quisquam maiores
        fugiat! Ipsa itaque alias, nam perspiciatis odio eaque debitis facilis
        cumque dignissimos est reprehenderit non vel architecto culpa maxime
        porro reiciendis delectus veritatis in, sit maiores minima. Minus
        veritatis cupiditate enim. Inventore quam tempore ex cumque nihil
        necessitatibus, corrupti officia optio aperiam voluptatum ipsam
        aspernatur harum accusantium. Eum perferendis ratione nostrum, rem in
        eos optio quae ipsum exercitationem laudantium quo voluptatem? Debitis
        totam hic dolore aperiam minima, voluptas dolor natus inventore
        blanditiis nemo temporibus eum possimus magnam nulla eveniet quia quod.
        Iste, unde sequi quia quaerat eaque blanditiis explicabo! Iusto,
        officia. Porro deleniti consequatur illo eum enim vero pariatur eius!
        Tenetur a deserunt harum necessitatibus architecto quo itaque cupiditate
        voluptatibus dolorem facere explicabo eaque, placeat fuga culpa error,
        quam atque ratione? Quas doloribus voluptas impedit illo suscipit, vitae
        beatae illum tempora voluptatum ex nostrum iusto quam! Aspernatur
        praesentium vel, exercitationem at magni doloribus sint tempore, nisi
        deleniti atque ullam. Illum, ea. Voluptate architecto fugit error
        perferendis. Quas, veniam suscipit debitis, dolores quia asperiores odio
        iste nemo neque ratione nam sit laborum deserunt ipsam culpa impedit
        molestiae voluptatem temporibus? Facere, temporibus nobis!
      </section>
      <section className="sections" id="formm">
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestiae
        natus magni tempore ut, officiis a id fugit quia voluptatum unde dolorem
        tempora? Voluptas quis suscipit necessitatibus, magnam nostrum iusto a.
        Eos suscipit natus corrupti impedit, laboriosam quaerat aliquam
        necessitatibus! Nisi explicabo suscipit mollitia asperiores? Cupiditate
        officia enim deleniti distinctio obcaecati architecto! Magni perferendis
        a totam doloremque saepe inventore libero reiciendis? Ducimus natus, sit
        assumenda beatae sint perspiciatis perferendis debitis. Saepe totam
        animi odit! Consequatur aspernatur voluptatum vero. Dicta pariatur
        dolorem architecto fugit, inventore quidem harum nulla possimus, quas
        laborum nemo? Adipisci quibusdam libero, quia beatae odit perferendis
        praesentium cupiditate, commodi accusamus fugiat id sequi nemo ipsam
        porro vel, et facilis ducimus omnis earum nulla optio sint. Dolorum aut
        fugiat assumenda! Placeat quisquam itaque minima voluptate assumenda
        non, corrupti exercitationem cumque natus nisi, nesciunt doloremque
        atque quod tenetur a repellat culpa neque. Repudiandae vel tempore
        cumque at. Laborum quidem dicta repellat! Hic, facilis suscipit minus
        delectus laborum consequuntur quos nobis praesentium optio, voluptates
        neque reprehenderit earum eligendi porro voluptas quidem temporibus
        architecto fugit accusamus quia voluptate mollitia debitis dolores
        dolorum. Nisi! Eveniet a distinctio impedit fugit, sint maiores! Vitae
        soluta iure officiis, laborum minus omnis cum iste asperiores accusamus
        excepturi aspernatur perferendis, commodi nemo iusto exercitationem
        maiores eum dolorem sit? Rem. Hic iure esse qui ad modi, dolores eius
        error minima accusantium maxime quae aliquid quisquam, soluta quia
        harum, libero perferendis blanditiis et quasi sit sint magnam incidunt!
        Veniam, esse non? Veniam quo, libero voluptatum debitis ducimus
        voluptates nostrum, blanditiis tempore dolorum impedit repellat eos
        assumenda sint sed cum iure. Voluptatum rerum aspernatur voluptatibus
        praesentium eius inventore, sit incidunt iusto mollitia. In illo ipsum
        officia corrupti ipsam voluptates fugiat eligendi, quo qui
        exercitationem tenetur inventore aliquam optio ad rem delectus molestias
        est eum ratione. Nulla quia quae quos fugiat sapiente? Minus! Alias iste
        magni a tenetur illo in odio eos consequatur nisi sint. A, numquam
        saepe? Autem enim, cum, voluptate fuga ducimus officiis adipisci
        recusandae excepturi dolor tempore eum vero facilis? Maiores aliquam
        repellat culpa adipisci esse voluptatum ducimus sed, reiciendis, aliquid
        nemo animi, mollitia omnis? Culpa totam adipisci, architecto repellat
        quaerat, pariatur, quidem mollitia nihil ab magni ipsam quam iure. Natus
        enim minima dolorem qui aut iusto! Doloribus ipsa corporis culpa,
        facilis nobis at dolores optio possimus ullam assumenda veritatis
        aperiam, rem doloremque explicabo magni perspiciatis repudiandae
        consequuntur amet ratione. Aperiam architecto quam expedita eos adipisci
        laborum fugit. Totam, in dignissimos unde sapiente facilis deserunt
        nihil doloribus laborum quibusdam, voluptate earum illum quidem, quis
        corporis vel culpa id quas. Deserunt. Voluptatum quis repudiandae ipsam
        iure nihil rem dolorum esse, culpa nostrum distinctio aperiam dolores
        eaque sed eveniet vel libero. Debitis nesciunt quisquam fugiat explicabo
        totam eligendi iusto accusamus consequatur maiores. Neque nobis vel vero
        fugit asperiores, ducimus velit architecto in repudiandae ratione
        distinctio consequuntur sapiente amet officiis at labore laudantium
        officia pariatur animi dolor. Asperiores culpa ipsum ab assumenda et.
        Vel provident vitae corporis iusto sit, laudantium ipsam possimus ipsum
        molestiae fugiat commodi neque ipsa aliquam a? Rem iure non incidunt
        modi? Illo incidunt accusantium totam nam eos aliquam exercitationem.
        Iusto veritatis itaque reprehenderit dignissimos, nulla, dolores culpa
        nostrum numquam, sit eius nobis quibusdam nam distinctio dicta sed.
        Molestiae voluptatibus velit harum unde similique sit eum dolores facere
        voluptatum officiis. Veritatis dolores molestias voluptate eum ut enim,
        at quae consectetur quia voluptatum autem fugiat ab labore pariatur
        totam impedit modi vel libero consequatur? Velit consectetur cum tempora
        ad quia? Consequatur. Delectus eaque sit tenetur pariatur, nihil esse
        harum corrupti, ipsa unde, numquam similique veniam fuga distinctio.
        Ipsam aliquam nemo exercitationem. Molestiae dolores laboriosam
        veritatis alias amet nemo animi incidunt repellat. A atque, quo voluptas
        aliquam voluptatem assumenda eligendi ab nesciunt culpa laboriosam,
        cumque accusantium suscipit facilis. Ipsum maiores voluptas commodi
        asperiores repudiandae cum, quod exercitationem explicabo autem ea
        quidem est. Adipisci modi explicabo odio, ducimus, dolorem maxime
        similique iusto quisquam commodi aspernatur nihil consequuntur et optio,
        perspiciatis tempore cupiditate cumque? Error culpa autem accusantium in
        eos ullam nulla natus similique. Maxime neque, commodi voluptate
        distinctio vitae culpa? Magni vel ad itaque facere eos totam,
        consectetur adipisci animi dolor error necessitatibus perspiciatis
        molestias velit inventore odio, nemo unde modi aperiam? Placeat?
        Quisquam eaque eos animi repudiandae numquam est ullam excepturi fuga
        incidunt nesciunt obcaecati veniam repellendus labore hic tempore cumque
        veritatis eligendi ea, nihil sit blanditiis aut ipsum sequi alias! Eos?
        Minus distinctio sint, in dolore voluptatem beatae, reprehenderit
        adipisci nemo aliquid, non accusamus accusantium? Corporis quos
        perspiciatis iste voluptates quo repudiandae ipsam? Consectetur, facere
        quidem fuga laborum consequuntur expedita voluptatibus. Asperiores ipsam
        aperiam commodi nisi reiciendis illum optio a laudantium fugiat
        officiis. Qui accusantium numquam incidunt, illo expedita delectus quasi
        ea id cumque dolorum culpa est tempora provident quisquam magni. Ad
        molestias nulla distinctio quae voluptatem? Asperiores, aperiam natus
        est, sapiente consequuntur id, reprehenderit sint vel voluptate
        cupiditate facere pariatur inventore corrupti. Quibusdam iste vero
        suscipit eaque, deserunt ex harum. A modi nisi quam suscipit sit
        inventore odit repellendus iste debitis blanditiis eum eligendi, aperiam
        ipsam. Ad, quis saepe inventore dolores labore distinctio quae cum.
        Commodi error aliquid autem totam. Quas, distinctio consectetur! A
        perspiciatis natus est deleniti dolores ad cum, sed itaque et voluptate
        quam, saepe, maiores nihil sunt aut modi laborum eos distinctio
        doloremque sit porro vitae alias! Consectetur iste, ratione iusto fuga
        ipsa distinctio incidunt quisquam eveniet sunt nihil hic perspiciatis
        recusandae, velit reiciendis animi placeat eaque a in molestias! Maiores
        sunt dolorem, dolore aliquid repellendus ipsum! Placeat neque qui
        consequatur amet nihil natus debitis soluta, sequi ipsa officiis
        deserunt veniam molestias quibusdam iusto deleniti dicta totam unde odio
        laborum quisquam tempora! Obcaecati sapiente autem magni tempora? Illo
        vitae nulla ex, expedita dolore doloremque recusandae architecto?
        Quaerat doloribus harum nobis id tempore officiis quos! Sit fugit,
        laudantium totam sunt distinctio dicta quo nihil adipisci dolor facilis
        optio. Necessitatibus, minima sunt dolore eligendi adipisci itaque. Nam
        magnam mollitia temporibus nulla. Magni ullam quaerat voluptates
        asperiores, ipsam enim harum nemo modi dicta laudantium illum facere,
        numquam commodi reprehenderit dolores! Voluptates fugit harum placeat
        facere. Eveniet provident architecto sint, ut placeat labore ratione,
        assumenda eos natus accusantium explicabo dolorem accusamus. Minus
        laboriosam impedit esse distinctio obcaecati odit, quisquam maiores
        fugiat! Ipsa itaque alias, nam perspiciatis odio eaque debitis facilis
        cumque dignissimos est reprehenderit non vel architecto culpa maxime
        porro reiciendis delectus veritatis in, sit maiores minima. Minus
        veritatis cupiditate enim. Inventore quam tempore ex cumque nihil
        necessitatibus, corrupti officia optio aperiam voluptatum ipsam
        aspernatur harum accusantium. Eum perferendis ratione nostrum, rem in
        eos optio quae ipsum exercitationem laudantium quo voluptatem? Debitis
        totam hic dolore aperiam minima, voluptas dolor natus inventore
        blanditiis nemo temporibus eum possimus magnam nulla eveniet quia quod.
        Iste, unde sequi quia quaerat eaque blanditiis explicabo! Iusto,
        officia. Porro deleniti consequatur illo eum enim vero pariatur eius!
        Tenetur a deserunt harum necessitatibus architecto quo itaque cupiditate
        voluptatibus dolorem facere explicabo eaque, placeat fuga culpa error,
        quam atque ratione? Quas doloribus voluptas impedit illo suscipit, vitae
        beatae illum tempora voluptatum ex nostrum iusto quam! Aspernatur
        praesentium vel, exercitationem at magni doloribus sint tempore, nisi
        deleniti atque ullam. Illum, ea. Voluptate architecto fugit error
        perferendis. Quas, veniam suscipit debitis, dolores quia asperiores odio
        iste nemo neque ratione nam sit laborum deserunt ipsam culpa impedit
        molestiae voluptatem temporibus? Facere, temporibus nobis!
      </section>
      <section className="sections" id="question">
        <div>
          <CollapseContent />
        </div>
      </section>
    </Main>
  );
};

export default Test;
