import React from "react";
import { styled } from "styled-components";
import Closemenudark from "../img/svg/closemenudark.svg";

const Style = styled.section`
  position: relative;
  .container {
    padding: 26px;
    background-color: blue;
    border-radius: 24px;
    border: 1px solid #cad3df;
    background-color: #fff;
    min-height: calc(100vh - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
    height: 100%;
    @media only screen and (max-width: 992px) and (min-width: 320px) {
      border-radius: 0;
    }
  }
  .t0 {
    color: #02324a;
    text-align: center;
    font-family: "Geologica-Bold", sans-serif;
    font-size: 46px;
    line-height: normal;
    @media only screen and (max-width: 700px) and (min-width: 320px) {
      font-size: 27px;
    }
  }
  .t1 {
    margin-top: 36px;
    color: #7c8b9f;
    font-family: "Geologica-Light", sans-serif;
    font-size: 16px;
    line-height: 150%;
  }

  .closemenudark {
    position: absolute;
    top: 35px;
    right: 0;
  }
  .container_five_sub {
    padding: 0 50px;

    @media only screen and (max-width: 700px) and (min-width: 320px) {
      padding: 0;
    }
  }
`;
const InfoTwo = ({ title, text, showItem, setServiceShow, tt }) => {
  return (
    <>
      <Style>
        {showItem === true && title ? (
          <div className="closemenudark cursor">
            <img
              src={Closemenudark}
              onClick={() => setServiceShow(false)}
              width={44}
              height={44}
              alt=""
            />
          </div>
        ) : null}
        <div
          className="container"
          style={
            tt
              ? { border: "none", minHeight: "auto", marginBottom: "30px" }
              : {}
          }
        >
          <div className="infoSubb">
            <div className="container_five">
              <div className="container_five_sub">
                <p className="t0">{title}</p>
                <p className="t1">{text}</p>
              </div>
            </div>
          </div>
        </div>
      </Style>
    </>
  );
};

export default InfoTwo;
