import React, { useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { styled } from "styled-components";
import Social1 from "../img/svg/social1.svg";
import Social2 from "../img/svg/social2.svg";
import Social3 from "../img/svg/social3.svg";
import CheckRound from "../img/svg/checkRound.svg";
import "react-phone-input-2/lib/style.css";
import { GetNotAuthInstance } from "../helpers/httpClient";
import { setLoading } from "../redux";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import IframeResizer from "iframe-resizer-react";

const ContainerCheckbox = styled.div`
  .container_checkbox {
    display: block;
    position: relative;
    padding-left: 26px;
    margin-bottom: 25px;
    margin-left: 6px;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #7c8b9f;
    font-family: "Geologica-Light", sans-serif;
    font-size: 16px;
    line-height: normal;
    transform: translate(0, 7px);
  }

  /* Hide the browser's default checkbox */
  .container_checkbox input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: -8px;
    height: 24px;
    width: 24px;
    border-radius: 5px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  /* .container_checkbox:hover input ~ .checkmark {
    background-color: #ccc;
  } */

  /* When the checkbox is checked, add a blue background */
  .container_checkbox input:checked ~ .checkmark {
    background-color: #0193de;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container_checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container_checkbox .checkmark:after {
    left: 8px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const Style = styled.section`
  padding-top: 26px;
  /* @media only screen and (max-width: 576px) and (min-width: 320px) {
    padding-top: 50px;
  } */
  .columnBox {
    margin: 0;
    margin-top: 36px;
    width: calc(100% + 31px);
    display: flex;
    flex-flow: row wrap;
    margin-left: -16px;
    @media only screen and (max-width: 992px) and (min-width: 320px) {
      flex-direction: column !important;
    }
  }

  .columnBoxSub {
    position: relative;
    margin: 15px;
    width: calc((100% / 2) - 32px);
    margin-bottom: 24px;

    @media only screen and (max-width: 992px) and (min-width: 320px) {
      width: calc(100% - 30px) !important;
    }
    .info {
      background: #f7fbfe;
      border-radius: 24px;

      .sub {
        padding: 36px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 500px;
        @media only screen and (max-width: 992px) and (min-width: 320px) {
          padding: 18px;
        }
      }
      .t1 {
        font-family: "Geologica-Bold", sans-serif;
        color: #02324a;
        font-size: 32px;
        line-height: normal;
        margin-bottom: 16px;
        @media only screen and (max-width: 992px) and (min-width: 320px) {
          font-size: 22px;
        }
      }
      .t2 {
        color: #7c8b9f;
        font-family: "Geologica-Light", sans-serif;
        font-size: 16px;
        line-height: 150%;
        margin-bottom: 15px;
      }
      .t3 {
        color: #02324a;
        font-family: "Geologica-Regular", sans-serif;
        font-size: 16px;
        line-height: normal;
      }
      .info_social {
        span {
          font-family: "Geologica-Regular", sans-serif;
          font-size: 16px;
          line-height: normal;
          a {
            margin-bottom: 20px;
            color: #02324a;
            display: flex;
            align-items: center;
          }
          img {
            margin-right: 12px;
          }
          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
      }
    }
    .info2 {
      height: 100%;
      border-radius: 24px;
      border: 1px solid #cad3df;
      background: #fff;
      /* padding: 36px; */
      overflow: hidden;
      height: 562px;
      @media only screen and (max-width: 992px) and (min-width: 320px) {
        padding: 18px;
      }
      iframe {
        border: none;
        width: 100%;
        height: 100%;
      }
      .need {
        color: red;
        font-family: "Geologica-Light", sans-serif;
        font-size: 16px;
        line-height: 150%;
        margin-top: -10px;
        margin-bottom: 10px;
      }
      form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 70px);
      }
      .t1 {
        color: #02324a;
        font-family: "Geologica-SemiBold", sans-serif;
        font-size: 22px;
        line-height: normal;
        margin-bottom: 24px;
      }
      .btnmain {
        /* margin-top: 70px; */
      }
      input {
        border-radius: 16px;
        border: 1px solid #cad3df;
        background: #fff;
        padding: 16px 20px;
        height: auto;
        width: calc(100% - 42px);
        color: #7c8b9f;
        font-family: "Geologica-Light", sans-serif;
        font-size: 16px;
        line-height: normal;
        margin-bottom: 16px;
      }
      textarea {
        border-radius: 16px;
        border: 1px solid #cad3df;
        background: #fff;
        padding: 16px 20px;
        height: 133px;
        width: calc(100% - 42px);
        color: #7c8b9f;
        font-family: "Geologica-Light", sans-serif;
        font-size: 16px;
        line-height: normal;
        margin-bottom: 16px;
      }
      .flag-dropdown {
        display: none;
      }
      .react-tel-input {
        margin-bottom: 16px;
        .form-control {
          padding-left: 0;
          border-radius: 16px;
          border: 1px solid #cad3df;
          background: #fff;
          padding: 16px 20px;
          height: auto;
          width: 100%;
          color: #7c8b9f;
          font-family: "Geologica-Light", sans-serif;
          font-size: 16px;
          line-height: normal;
        }
      }
      button {
        color: #fff;
        font-family: "Geologica-SemiBold", sans-serif;
        font-size: 16px;
        line-height: normal;
        border-radius: 16px;
        background: #0193de;
        width: 100%;
        padding: 16px;
      }
    }
    @media only screen and (max-width: 765px) and (min-width: 320px) {
      width: 100%;
    }

    .sendedSms {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      flex-direction: column;
      .s1 {
        color: #02324a;
        text-align: center;
        font-family: "Geologica-SemiBold", sans-serif;
        font-size: 22px;
        line-height: normal;
        margin-top: 24px;
      }
      .s2 {
        color: #7c8b9f;
        text-align: center;
        font-size: 16px;
        font-family: "Geologica-Light", sans-serif;
        line-height: 150%;
        padding: 0 90px;
        margin-top: 8px;
      }
    }
  }

  .iframee {
    iframe {
      border: none;
      width: 100%;
      height: 100%;
    }
  }
  .layer {
    width: 100%;
    height: 20px;
    background: #a7e1fb;
  }
`;

const SelectsDiv = styled.div`
  .course-image {
    img {
      width: 70px;
      height: 70px;
      object-fit: cover;
    }
  }
`;
const FrameResizerMain = styled.div`
  background: #a7e1fb;
  border-radius: 10px;
  overflow: hidden;
  z-index: -1;
`;

const FormComponent = ({ info, title }) => {
  const iframeRef = useRef(null);
  const [obj, setObj] = useState({ status: true, sendedSms: false });
  const [h, setH] = useState(1);
  const [objE, setObjE] = useState({});
  const [typingTimeOut, setTypingTimeOut] = useState(0);
  const webinarImage = useRef(null);
  const [image, setImage] = useState(false);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const handleChange = (e) => {
    setObj({ ...obj, [e.target.name]: e.target.value });
    setObjE({ ...objE, [e.target.name]: false });
  };

  const handleForm = (ev) => {
    ev.preventDefault();

    let t = true,
      e = {};
    if (!obj.name) {
      t = false;
      e = { ...e, name: true };
    }
    if (obj.phone?.length != 12) {
      t = false;
      e = { ...e, phone: true };
    }

    const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
    if (regEx.test(obj?.email)) {
      e = { ...e, email: false };
    } else if (!regEx.test(`enter code here`) && obj?.email !== "") {
      t = false;
      e = { ...e, email: true };
    }
    if (!obj.resume) {
      t = false;
      e = { ...e, resume: true };
    }
    if (obj.status === false) {
      t = false;
      e = { ...e, status: true };
    }

    const formData = new FormData();

    // if (image) {
    //   try {
    //     const file = webinarImage.current.files[0];
    //     formData.append("image", file);
    //   } catch {
    //     setImage(false);
    //   }
    // }
    formData.append("full_name", obj?.name ? obj?.name : "");
    formData.append("phone", obj?.phone ? obj?.phone : "");
    formData.append("email", obj?.email ? obj?.email : "");
    formData.append("link", obj?.resume ? obj?.resume : "");

    if (t) {
      GetNotAuthInstance()
        .post("api/v1/resume", formData)
        .then((res) => {
          setObj((pV) => ({
            ...pV,
            sendedSms: true,
          }));

          setTypingTimeOut(
            setTimeout(() => {
              setObj({ status: true, sendedSms: false });
            }, 10000)
          );

          if (typingTimeOut) {
            clearTimeout(typingTimeOut);
          }
        })
        .catch((error) => {})
        .finally(() => dispatch(setLoading(false)));
    } else {
      setObjE(e);
      dispatch(setLoading(false));
    }
  };

  const handleCheckBox = () => {
    setObj((pV) => ({ ...pV, status: !obj?.status }));
  };
  const handleOnChange = (value) => {
    if (value.length >= 4) {
      setObj({ ...obj, phone: value });
    } else {
      if (h == 1) {
        setObj({ ...obj, phone: "+998" });
        setH(2);
      } else {
        setObj({ ...obj, phone: "998" });
        setH(1);
      }
    }
  };
  const handleOnBlur = (event) => {
    const i = event.target.value;
    const p =
      i.slice(1, 4) +
      i.slice(6, 8) +
      i.slice(10, 13) +
      i.slice(14, 16) +
      i.slice(17, 19);
    setObj({ ...obj, phone: p });
  };

  return (
    <>
      <Style id="formm">
        <div className="container">
          {/* <div className="columnBox">
            <div className="columnBoxSub">
              <div className="info">
                <div className="sub">
                  <div>
                    <p className="t1">{t("form_career.title")}</p>
                    <p className="t2">{t("form_career.desc")}</p>
                  </div>
                  <div className="info_social">
                    <span>
                      <a
                        href={`tel:+${info?.phone?.replace(
                          /['('')'' ''+'-]/g,
                          ""
                        )}`}
                      >
                        <img src={Social1} alt="" /> {info?.phone}
                      </a>
                    </span>
                    <span>
                      <a href={`mailto:${info?.email}`}>
                        <img src={Social2} alt="" /> {info?.email}
                      </a>
                    </span>
                    <span>
                      <a
                        href="https://goo.gl/maps/EMvAMXPCaZdu3hfk6"
                        target={"_blank"}
                      >
                        <img src={Social3} alt="" /> {info?.address}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="columnBoxSub">
              <div className="info2">
                <iframe
                  sandbox="allow-same-origin "
                  id="myiFrame"
                  src="https://docs.google.com/forms/d/e/1FAIpQLSfmq3XNakDvwd1LOyR3M76nkCBWXUIA055HQdfMExiscMsixA/viewform"
                  title="description"
                ></iframe>
              </div>
            </div>
          </div> */}
          {/* iframee */}
          {/* <div className="">
            <iframe
              sandbox="allow-same-origin "
              id="myiFrame"
              src="https://docs.google.com/forms/d/e/1FAIpQLSfmq3XNakDvwd1LOyR3M76nkCBWXUIA055HQdfMExiscMsixA/viewform"
              title="description"
              width="600"
              height="500"
            ></iframe>
          </div> */}
          <FrameResizerMain>
            <div className="layer"></div>
            <IframeResizer
              forwardRef={iframeRef}
              heightCalculationMethod="lowestElement"
              inPageLinks
              log
              src="https://docs.google.com/forms/d/e/1FAIpQLSfmq3XNakDvwd1LOyR3M76nkCBWXUIA055HQdfMExiscMsixA/viewform"
              scrolling="no"
              width="100%"
              style={{ border: "none", minHeight: "1000px" }}
            />
          </FrameResizerMain>
        </div>
      </Style>
    </>
  );
};

export default FormComponent;
