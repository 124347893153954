import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import CollapseContent from "../../components/CollapseContent";
import { BreadCrumb } from "../../components/GlobalStyle";
import InfoTwo from "../../components/InfoTwo";
import { GetNotAuthInstance } from "../../helpers/httpClient";
import { setLoading } from "../../redux";
import DotsMenu from "../../components/DotsMenu";
import { HashLink } from "react-router-hash-link";

const Service = () => {
  const [services, setServices] = useState({});
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    getServices();
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [i18n.language]);

  const getServices = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get(`api/v1/service/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setServices(res?.data?.data[0]);
        }
      })
      .catch((error) => setServices([]))
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <>
      <div className="container">
        <BreadCrumb>
          <Link to="/">{t("footer_nav.main")}</Link>
          <span className="line"> / </span>
          <HashLink smooth to="/#servicess">
            {t("footer_nav.service")}
          </HashLink>
          <span className="line"> / </span>
          <span>{services?.name}</span>
        </BreadCrumb>
      </div>
      <DotsMenu />
      <InfoTwo
        title={services?.name}
        text={<p dangerouslySetInnerHTML={{ __html: services?.body }} />}
      />
      <CollapseContent question="question" />
    </>
  );
};

export default Service;
