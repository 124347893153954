import React, { useEffect, useRef, useState } from "react";
import Brands from "../../components/Brands";
import CollapseContent from "../../components/CollapseContent";
import FormComponent from "../../components/FormComponent";
import { styled } from "styled-components";
import AboutSwiper from "../../components/AboutSwiper";
import { useDispatch, useSelector } from "react-redux";
import Info from "../../components/Info";
import Waveform from "../../components/Waveform";
import WhyGood from "../../components/WhyGood";
import UserNoFound from "../../img/svg/usernofound.png";

import { GetNotAuthInstance } from "../../helpers/httpClient";
import { setLoading } from "../../redux";
import HeaderMain from "../../components/HeaderMain";
import FormComment from "../../components/FormComment";
import OurService from "../../components/OurService";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Testt from "../../img/testt.ogg";

const SwiperStyle = styled.div`
  padding-top: 60px;
  @media only screen and (max-width: 576px) and (min-width: 320px) {
    padding-top: 50px;
  }

  .card {
    border-radius: 24px;
    border: 1px solid #cad3df;
    background: #fff;
    width: calc(100% - 30px) !important;
    margin-left: 15px;
    .info {
      padding: 26px;
      position: relative;
      display: flex;
      /* align-items: center; */
      flex-direction: column;
      .userNoFound {
        width: 64px;
        height: 64px;
        object-fit: cover;
        border-radius: 50%;
        aspect-ratio: 1;
      }
      .info_t1 {
        color: #02324a;
        font-family: "Geologica-SemiBold", sans-serif;
        font-size: 22px;
        line-height: normal;
      }
      .info_t2 {
        color: #7c8b9f;
        font-family: "Geologica-Light", sans-serif;
        font-size: 16px;
        line-height: normal;
      }
    }
    .info_sub {
      margin-top: 44px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .slick-dots {
    transform: translate(0, 20px);
    ul {
      li {
        margin: 0;
      }
    }
    .custompage {
      background: #cad3df;
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
    .slick-active {
      width: 60px;
      margin-right: 5px;
      overflow: hidden;
      .custompage {
        transition: 250ms ease-in-out;
        overflow: hidden;
        position: relative;
        background: #cad3df;
        width: 60px;
        height: 12px;
        border-radius: 8px;
      }
    }
  }
  @media only screen and (max-width: 576px) and (min-width: 320px) {
    /* .infoSubb {
      flex-direction: column;
    } */
    .info {
      align-items: start !important;
    }
  }
`;
const Home = () => {
  const [reviews, setReviews] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [actionOneSlider, setActionOneSlider] = useState(false);
  const [actionTwoSlider, setActionTwoSlider] = useState(false);

  const { info, images } = useSelector((state) => state);
  const [autoPly, setAutoPly] = useState(false);

  let sliderRef = useRef(null);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (actionTwoSlider) {
      sliderRef.slickPlay();
    }
  }, [sliderRef, actionTwoSlider]);

  useEffect(() => {
    getReviews();
  }, [i18n.language]);

  const getReviews = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get("api/v1/reviews")
      .then((res) => {
        if (res.status === 200) {
          let data = res?.data?.data;
          if (data) {
            setReviews(data);
          } else {
            setReviews([]);
          }
        }
      })
      .catch((error) => setReviews([]))
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  let settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    spaceBetween: 30,
    autoplay: autoPly,
    autoplaySpeed: 2500,
    pauseOnHover: false,
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
    customPaging: (i) => <div className="custompage"></div>,
  };

  const onMouseDownF = () => {
    sliderRef.slickPause();
    setAutoPly(false);
  };

  const onMouseUpF = () => {
    sliderRef.slickPlay();
    setAutoPly(true);
  };

  useEffect(() => {
    actionSliderOne();
    actionSliderTwo();
  }, []);

  const actionSliderOne = () => {
    if (window.scrollY >= 700) {
      setActionOneSlider(true);
    } else {
      setActionOneSlider(false);
    }
  };
  window.addEventListener("scroll", actionSliderOne);
  window.addEventListener("load", actionSliderOne);

  const actionSliderTwo = () => {
    if (window.scrollY >= 2400) {
      setActionTwoSlider(true);
    } else {
      setActionTwoSlider(false);
    }
  };
  window.addEventListener("scroll", actionSliderTwo);
  window.addEventListener("load", actionSliderTwo);

  return (
    <>
      <HeaderMain />
      <div style={{ height: "60px" }} id="comp" />
      <Info />
      <AboutSwiper images={images} actionOneSlider={actionOneSlider} />
      <div className="btnLinkMain">
        <Link to="/about" className="btnLink" style={{ marginTop: "36px" }}>
          {t("our_service.more")}
        </Link>
      </div>
      <WhyGood
        title={t("why_us.titlemain")}
        title1={t("why_us.title1")}
        title2={t("why_us.title2")}
        title3={t("why_us.title3")}
        title4={t("why_us.title4")}
        desc1={t("why_us.desc1")}
        desc2={t("why_us.desc2")}
        desc3={t("why_us.desc3")}
        desc4={t("why_us.desc4")}
        st={true}
      />

      <div className="container">
        <SwiperStyle>
          <p className="title_app" style={{ marginBottom: "36px" }}>
            {t("another.view")}
          </p>

          {reviews && reviews?.length > 0 ? (
            <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
              {reviews?.map((item, index) => (
                <div
                  className="card"
                  key={index}
                  // onMouseDown={onMouseDownF}
                  // onMouseUp={onMouseUpF}
                >
                  <div className="info">
                    <div
                      className="infoSubb"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <img
                        src={item?.image ? item?.image : UserNoFound}
                        className="userNoFound"
                        alt=""
                      />
                      <div
                        className="info_t1main"
                        style={{ marginLeft: "16px" }}
                      >
                        <p className="info_t1">
                          {item?.first_name} <br /> {item?.last_name}
                        </p>
                      </div>
                    </div>
                    <div
                      className="infoSubb"
                      style={{
                        display: "flex",
                        marginTop: "15px",
                        minHeight: "40px",
                      }}
                    >
                      <p className="info_t2">{item?.position}</p>
                    </div>
                    <div className="info_sub">
                      {/* <Waveform audio={item?.audio} /> */}
                      <Waveform
                        // audio={Testt}
                        audio={item?.audio}
                        toggle={toggle}
                        setToggle={setToggle}
                        playSlider={() => sliderRef.slickPlay()}
                        pauseSlider={() => sliderRef.slickPause()}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          ) : null}
        </SwiperStyle>
      </div>
      <OurService />
      <Brands title={t("another.trust")} />
      <FormComment title={t("another.fill_form")} />

      <CollapseContent />
    </>
  );
};

export default Home;
