import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
const Style = styled.section`
  padding: 24px 0 56px 0;
  .t1 {
    margin-top: 36px;
    color: #7c8b9f;
    font-family: "Geologica-Light", sans-serif;
    font-size: 16px;
    line-height: 150%;
  }
`;
const Info = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Style>
        <div className="container_two">
          <p className="title_app">{t("about_company.title")}</p>
          <p className="t1">
            {t("about_company.desc1")} <br />
            <br /> {t("about_company.desc2")}
          </p>
        </div>
      </Style>
    </>
  );
};

export default Info;
