import React, { useEffect } from "react";
import Brands from "../../components/Brands";
import CollapseContent from "../../components/CollapseContent";
import FormComponent from "../../components/FormComponent";
import Info from "../../components/Info";
import Waveform from "../../components/Waveform";
import WhyGood from "../../components/WhyGood";
import UserNoFound from "../../img/svg/usernofound.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { setLoading } from "../../redux";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { styled } from "styled-components";
import AboutSwiper from "../../components/AboutSwiper";
import { BreadCrumb } from "../../components/GlobalStyle";
import InfoTwo from "../../components/InfoTwo";
import { Link } from "react-router-dom";
import SwiperGroup from "../../components/SwiperGroup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DotsMenu from "../../components/DotsMenu";
import { useState } from "react";
import { GetNotAuthInstance } from "../../helpers/httpClient";
import SwiperGroup2 from "../../components/SwiperGroup2";
const SwiperStyle = styled.div`
  margin-bottom: 50px;
  @media only screen and (max-width: 576px) and (min-width: 320px) {
    margin-bottom: 30px;
  }
  .swiper-pagination-bullet {
    /* transition: 300ms; */
  }
  .swiper {
    padding-bottom: 38px;
  }
  .swiper-pagination-bullet-active {
    width: 60px;
    border-radius: 16px;
  }
  .card {
    padding: 26px;
    border-radius: 24px;
    border: 1px solid #cad3df;
    background: #fff;
    .info {
      position: relative;
      margin-bottom: 44px;
      display: flex;
      align-items: center;
      .userNoFound {
        width: 64px;
        height: 64px;
        object-fit: cover;
        border-radius: 50%;
      }
      .info_t1 {
        color: #02324a;
        font-family: "Geologica-SemiBold", sans-serif;
        font-size: 22px;
        line-height: normal;
      }
      .info_t2 {
        color: #7c8b9f;
        font-family: "Geologica-Light", sans-serif;
        font-size: 16px;
        line-height: normal;
      }
    }
    .info_sub {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;
const About = () => {
  const [cartificateList, setCartificateList] = useState([]);
  const [actionOneSlider, setActionOneSlider] = useState(false);
  const [actionTwoSlider, setActionTwoSlider] = useState(false);
  const [actionThreeSlider, setActionThreeSlider] = useState(false);
  const [typingTimeOut, setTypingTimeOut] = useState(0);

  const { t, i18n } = useTranslation();
  const { images, teams, loading } = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    getCertificate();
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [i18n.language]);

  useEffect(() => {
    actionSliderOne();
    actionSliderTwo();
  }, []);

  const actionSliderOne = () => {
    setTypingTimeOut(
      setTimeout(() => {
        if (window.scrollY >= 200) {
          setActionOneSlider(true);
        } else {
          setActionOneSlider(false);
        }
      }, 600)
    );
    if (typingTimeOut) {
      clearTimeout(typingTimeOut);
    }
  };

  const actionSliderTwo = () => {
    setTypingTimeOut(
      setTimeout(() => {
        if (window.scrollY >= 700) {
          setActionTwoSlider(true);
        } else {
          setActionTwoSlider(false);
        }
      }, 500)
    );
    if (typingTimeOut) {
      clearTimeout(typingTimeOut);
    }
  };

  const actionSliderThree = () => {
    if (window.scrollY >= 2100) {
      setActionThreeSlider(true);
    } else {
      setActionTwoSlider(false);
    }
  };

  const getCertificate = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get("api/v1/certificates")
      .then((res) => {
        if (res.status === 200) {
          let data = res?.data?.data;
          if (data) {
            let nD = [];
            data.forEach((el, index) => {
              nD = [
                ...nD,
                {
                  id: el?.id,
                  image: el?.image,
                  title: el?.title,
                  // bColor: "rgb(248, 248, 247)",
                  bColor: "red",
                },
              ];
            });
            setCartificateList(data);
          } else {
            setCartificateList([]);
          }
        }
      })
      .catch((error) => setCartificateList([]))
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  window.addEventListener("scroll", actionSliderOne);
  window.addEventListener("load", actionSliderOne);
  window.addEventListener("scroll", actionSliderTwo);
  window.addEventListener("load", actionSliderTwo);
  window.addEventListener("scroll", actionSliderThree);
  window.addEventListener("load", actionSliderThree);

  return (
    <>
      <div className="container">
        <BreadCrumb>
          <Link to="/">{t("footer_nav.main")}</Link>
          <span className="line"> / </span>
          <span>{t("footer_nav.company")}</span>
        </BreadCrumb>
      </div>
      <DotsMenu />
      <InfoTwo
        title={t("footer_nav.company")}
        tt={true}
        text={
          <>
            {t("about_company.desc1")}
            <br />
            <br />
            {t("about_company.desc2")}
          </>
        }
      />
      <AboutSwiper images={images} actionOneSlider={actionOneSlider} />
      <SwiperGroup
        title={t("our_group.title")}
        teams={teams}
        actionTwoSlider={actionTwoSlider}
      />
      <WhyGood
        title={t("why_us.titlemain")}
        title1={t("why_us.title1")}
        title2={t("why_us.title2")}
        title3={t("why_us.title3")}
        title4={t("why_us.title4")}
        desc1={t("why_us.desc1")}
        desc2={t("why_us.desc2")}
        desc3={t("why_us.desc3")}
        desc4={t("why_us.desc4")}
        st={true}
      />
      <div style={{ marginTop: "76px" }}>
        <SwiperGroup2
          title={"Рекомендации"}
          cartificateList={cartificateList}
          actionThreeSlider={actionThreeSlider}
          setCartificateList={setCartificateList}
        />
      </div>
      <Brands title={t("another.trust")} />
    </>
  );
};

export default About;
