import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { GetNotAuthInstance } from "../helpers/httpClient";
import { setLoading } from "../redux";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Style = styled.section`
  padding-top: 62px;
  /* @media only screen and (max-width: 576px) and (min-width: 320px) {
    padding-top: 50px;
  } */
  .header {
    padding: 36px;
    color: #02324a;
    font-family: "Geologica-SemiBold", sans-serif;
    font-size: 22px;
    line-height: normal;
    width: calc(100% - 150px);

    @media only screen and (max-width: 992px) and (min-width: 320px) {
      padding: 22px;
      font-size: 16px;
    }
  }
  .header_sub {
    padding: 0 36px 36px 36px;

    @media only screen and (max-width: 992px) and (min-width: 320px) {
      padding: 0 22px 22px 22px;
    }
    color: #7c8b9f;
    font-family: "Geologica-Light", sans-serif;
    font-size: 16px;
    line-height: 150%;
    background: #f7fbfe;
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }
  .accordion__item + .accordion__item {
    border-top: 0;
  }
  .accordion {
    border: none;
    border-radius: 0;
  }
  .accordion__item {
    margin: 24px 0;
  }
  .accordion__button:hover {
    background-color: transparent;
  }
  .accordion__button {
    position: relative;
    padding: 0;
    border-radius: 24px;
    border: 1px solid #cad3df;
    background: #fff;
    transition: 300ms;
  }
  .accordion__button {
    display: flex;
    align-items: center;
  }
  .accordion__panel {
    padding: 0;
  }
  .accordion__button:before {
    position: absolute;
    right: 57px;
    z-index: 3;
    height: 6px;
    width: 6px;
    margin-right: 0;
    transform: rotate(45deg) translate(0, -3px);
    transition: 300ms;
    color: #fff;
  }
  .accordion__button[aria-expanded="true"]::before,
  .accordion__button[aria-selected="true"]::before {
    transform: rotate(-136deg) translate(0px, -3px);
    right: 53px;
  }
  .accordion__button[aria-expanded="true"],
  .accordion__button[aria-selected="true"] {
    border-radius: 0;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background: #f7fbfe;
    border: 1px solid transparent;
  }
  .accordion__button:after {
    position: absolute;
    display: inline-block;
    content: "";
    height: 22px;
    width: 22px;
    margin-right: 12px;
    background: #0193de;
    right: 36px;
    border-radius: 7px;
  }
  .title_app {
  }
`;

const CollapseContent = ({ question }) => {
  const [list, setList] = useState([]);

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getCollapse();
  }, [i18n.language]);

  const getCollapse = () => {
    dispatch(setLoading(true));
    GetNotAuthInstance()
      .get("api/v1/faqs")
      .then((res) => {
        if (res.status === 200) {
          let data = res?.data?.data;
          if (data) {
            setList(data);

            const urlHash = window.location.hash;
            if (urlHash.substring(1) === "question") {
              setTimeout(() => {
                if (urlHash.length) {
                  const element = document.getElementById(urlHash.substring(1));
                  element.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }, 80);
            }
          } else {
            setList([]);
          }
        }
      })
      .catch((error) => setList([]))
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <>
      <Style id="question">
        <div className="container_two">
          <p className="title_app" style={{ paddingTop: "36px" }}>
            {t("collapse_content.faq")}
          </p>

          <div className="collapse">
            <Accordion allowZeroExpanded>
              {list.map((item, index) => (
                <AccordionItem key={index}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <div className="header">{item.title}</div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="header_sub">{item.body}</div>
                  </AccordionItemPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </Style>
    </>
  );
};

export default CollapseContent;
