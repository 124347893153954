import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { createGlobalStyle, styled } from "styled-components";
import { GetNotAuthInstance } from "../helpers/httpClient";
import { setLoading } from "../redux";
import LinkTo from "../img/svg/linkto.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfoTwo from "./InfoTwo";

const Style = styled.div`
  padding-top: 120px;
  /* @media only screen and (max-width: 576px) and (min-width: 320px) {
    padding-top: 50px;
  } */
  .serviceBtns {
    display: flex;
    flex-wrap: nowrap;
    margin: 32px 0;
    overflow: scroll;
    width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
    button {
      min-width: 215px;
      margin-right: 16px;
      background: transparent;
      color: #7c8b9f;
      font-family: "Geologica-Regular", sans-serif;
      font-size: 15px;
      line-height: normal;
      padding: 16px 24px;
      transition: 300ms;
      border-radius: 16px;
      &:hover {
        border-radius: 16px;
        border: 1px solid #0193de;
        background: #f7fbfe;
        color: #0193de;
      }
    }
  }
  .cardService {
    border-radius: 24px;
    border: 1px solid #cad3df;
    background: #fff;
    padding: 36px 28px;
    .t1 {
      color: #02324a;
      font-family: "Geologica-SemiBold", sans-serif;
      font-size: 22px;
      line-height: normal;
      min-height: 56px;
      margin-bottom: 24px;
    }
    .aLink {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      color: #0193de;
      font-family: "Geologica-SemiBold", sans-serif;
      font-size: 16px;
      line-height: normal;
      img {
        margin-left: 8px;
      }
    }
  }
  .columnBox {
    margin: 0;
    margin-top: 36px;
    width: calc(100% + 31px);
    display: flex;
    flex-flow: row wrap;
    margin-left: -16px;
  }
  .columnBoxSub {
    position: relative;
    margin: 15px;
    width: calc((100% / 3) - 31px);

    @media (max-width: 992px) and (min-width: 765px) {
      width: calc((100% / 2) - 32px) !important;
    }
    @media only screen and (max-width: 765px) and (min-width: 320px) {
      width: calc(100% - 30px) !important;
    }
    margin-bottom: 24px;
    border-radius: 24px;
    background: #f7fbfe;
  }
`;

const ModalSectionLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  opacity: 0.7;
  background-color: #fff;
`;
const ModalSection = styled.div`
  position: fixed;
  top: -15px;
  left: -30px;
  width: calc(100% - 30px);
  height: 100%;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;

  @media only screen and (max-width: 992px) and (min-width: 320px) {
    width: 100% !important;
  }
`;

const OverFlow = createGlobalStyle`
  body{
    overflow: hidden;
  }
`;

const OurService = () => {
  const [serviceCategory, setServiceCategory] = useState([]);
  const [services, setServices] = useState([]);
  const [tab, setTab] = useState(0);
  const [serviceShow, setServiceShow] = useState(false);
  const [showItem, setShowItem] = useState(false);
  const [serviceObj, setServiceObj] = useState({});

  const { t, i18n } = useTranslation();
  const handleTab = (i) => setTab(i);

  const dispatch = useDispatch();
  useEffect(() => {
    getServiceCategory();
    getServices();
  }, [i18n.language]);

  const getServiceCategory = () => {
    GetNotAuthInstance()
      .get("api/v1/service-category")
      .then((res) => {
        if (res.status === 200) {
          let data = res?.data?.data;
          if (data) {
            setServiceCategory(data);
          } else {
            setServiceCategory([]);
          }
          const urlHash = window.location.hash;
          if (urlHash.substring(1) === "servicess") {
            setTimeout(() => {
              if (urlHash.length) {
                const element = document.getElementById(urlHash.substring(1));
                element.scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }
            }, 80);
          }
        }
      })
      .catch((error) => setServiceCategory([]))
      .finally(() => {});
  };

  const getServices = (val) => {
    GetNotAuthInstance()
      .get(`api/v1/services${val === undefined ? "" : `?category_id=${val}`}`)
      .then((res) => {
        if (res.status === 200) {
          let data = res?.data?.data;
          if (data) {
            setServices(data);
            setTimeout(() => {
              setShowItem(true);
            }, 1000);
          } else {
            setServices([]);
          }
        }
      })
      .catch((error) => setServices([]))
      .finally(() => {});
  };

  const getServicesSingle = (id) => {
    GetNotAuthInstance()
      .get(`api/v1/service/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setServiceObj(res?.data?.data[0]);
        }
      })
      .catch((error) => setServiceObj({}))
      .finally(() => {});
  };

  return (
    <>
      <Style id="servicess">
        <div className="container">
          <div className="title_app">{t("our_service.title")}</div>
          <div className="serviceBtns">
            <button
              type="button"
              onClick={() => {
                getServices(undefined);
                handleTab(0);
              }}
              style={
                tab === 0
                  ? {
                      color: "#0193DE",
                      borderRadius: "16px",
                      border: "1px solid #0193DE",
                      background: "#F7FBFE",
                    }
                  : {}
              }
            >
              {t("our_service.all_service")}
            </button>
            {serviceCategory && serviceCategory?.length > 0
              ? serviceCategory?.map((item, index) => {
                  return (
                    <button
                      type="button"
                      key={index}
                      onClick={() => {
                        getServices(item?.id);
                        handleTab(index + 1);
                      }}
                      style={
                        tab === index + 1
                          ? {
                              color: "#0193DE",
                              borderRadius: "16px",
                              border: "1px solid #0193DE",
                              background: "#F7FBFE",
                            }
                          : {}
                      }
                    >
                      {item?.name}
                    </button>
                  );
                })
              : null}
          </div>
          <div className="columnBox">
            {services && services?.length > 0
              ? services?.map((item, index) => {
                  return (
                    <div className="columnBoxSub" key={index}>
                      <div className="cardService">
                        <p className="t1">{item?.name}</p>
                        <div
                          className="aLink"
                          onClick={() => {
                            getServicesSingle(item?.id);
                            setServiceShow(true);
                          }}
                        >
                          {t("our_service.more")} <img src={LinkTo} alt="" />
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </Style>
      {serviceShow === true ? (
        <ModalSectionLayer>
          <OverFlow />
        </ModalSectionLayer>
      ) : null}
      {serviceShow === true && serviceObj?.name ? (
        <ModalSection>
          <InfoTwo
            title={serviceObj?.name}
            showItem={showItem}
            setServiceShow={setServiceShow}
            text={<p dangerouslySetInnerHTML={{ __html: serviceObj?.body }} />}
          />
        </ModalSection>
      ) : null}
    </>
  );
};

export default OurService;
