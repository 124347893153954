import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import WhyGoodSvg1 from "../img/svg/whygood1.svg";
import WhyGoodSvg2 from "../img/svg/whygood2.svg";
import WhyGoodSvg3 from "../img/svg/whygood3.svg";
import WhyGoodSvg4 from "../img/svg/whygood4.svg";

const Style = styled.section`
  /* @media only screen and (max-width: 576px) and (min-width: 320px) {
    padding-top: 50px;
  } */
  .columnBox {
    margin: 0;
    margin-top: 36px;
    width: calc(100% + 31px);
    display: flex;
    flex-flow: row wrap;
    margin-left: -16px;
  }

  .columnBoxSub {
    position: relative;
    margin: 15px;
    width: calc((100% / 2) - 31px);
    margin-bottom: 24px;
    border-radius: 24px;
    background: #f7fbfe;
    .info {
      padding: 36px;

      @media only screen and (max-width: 576px) and (min-width: 320px) {
        padding: 26px;
      }
      .t1 {
        margin-top: 50px;
        color: #02324a;
        font-family: "Geologica-SemiBold", sans-serif;
        font-size: 22px;
        line-height: normal;
        @media only screen and (max-width: 576px) and (min-width: 320px) {
          font-size: 18px;
          margin-top: 30px;
        }
      }
      .t2 {
        margin-top: 16px;
        font-family: "Geologica-Light", sans-serif;
        color: #7c8b9f;
        font-size: 16px;
        line-height: 150%;
      }
    }
    @media only screen and (max-width: 765px) and (min-width: 320px) {
      width: 100%;
    }
  }
`;
const WhyGood = ({
  title,
  title1,
  title2,
  title3,
  title4,
  desc1,
  desc2,
  desc3,
  desc4,
  st,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Style style={st ? { paddingTop: "96px" } : {}}>
        <div className="container">
          <p className="title_app">{title}</p>
          <div className="columnBox">
            <div className="columnBoxSub">
              <div className="info">
                <img src={WhyGoodSvg1} className="whyGoodSvg" alt="" />
                <p className="t1">{title1}</p>
                <p className="t2">{desc1}</p>
              </div>
            </div>
            <div className="columnBoxSub">
              <div className="info">
                <img src={WhyGoodSvg2} className="whyGoodSvg" alt="" />
                <p className="t1">{title2}</p>
                <p className="t2">{desc2}</p>
              </div>
            </div>
            <div className="columnBoxSub">
              <div className="info">
                <img src={WhyGoodSvg3} className="whyGoodSvg" alt="" />
                <p className="t1">{title3}</p>
                <p className="t2">{desc3}</p>
              </div>
            </div>
            <div className="columnBoxSub">
              <div className="info">
                <img src={WhyGoodSvg4} className="whyGoodSvg" alt="" />
                <p className="t1">{title4}</p>
                <p className="t2">{desc4}</p>
              </div>
            </div>
          </div>
        </div>
      </Style>
    </>
  );
};

export default WhyGood;
